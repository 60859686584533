import React,{ useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import LastdaysTabing from '../Kpis/LastdaysTabing';

import SubjectTable from './SubjectTable'; 
import GreetingTable from './GreetingTable'; 
import OpenerTable from './OpenerTable'; 
import FUOpenerTable from './FUOpenerTable'; 
import CTATable from './CTATable'; 
import CloserTable from './CloserTable'; 
import OptOutTable from './OptOutTable'; 
import ProblemTable from './ProblemTable'; 
import ValuePropTable from './ValuePropTable'; 
import StoryTable from './StoryTable'; 
import SenderTitleTable from './SenderTitleTable'; 
import DayTable from './DayTable'; 
import TimeTable from './TimeTable'; 
import LengthTable from './LengthTable'; 
import ActionTable from './ActionTable'; 


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));


export default function SimpleTabs({ToDate,FromDate,daterange}) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [FromDates,SetFromDates ] = React.useState(FromDate);
  const [ToDates, SetToDates] = React.useState(ToDate);
  useEffect(() => {  
    SetFromDates(FromDate);
    SetToDates(ToDate);
  }, [ToDate,FromDate,daterange]);
   

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [images, setImages] = React.useState([]);
  const maxNumber = 69;

    //const openTermsConditionPopup = false;
  

  return (
    <div className="tabdes">   
      <div> 
        <AppBar position="static">
          <Tabs value={value} onChange={handleChange} className="col-tabs" aria-label="simple tabs example">
            <Tab label="Subject" {...a11yProps(0)}>Overviewasdasd</Tab>
            <Tab label="Greeting" {...a11yProps(1)} /> 
            <Tab label="Opener" {...a11yProps(2)} /> 
            <Tab label="FUOpener" {...a11yProps(3)} /> 
            <Tab label="CTA" {...a11yProps(4)} /> 
            <Tab label="Closer" {...a11yProps(5)} /> 
            <Tab label="OptOut" {...a11yProps(6)} /> 
            <Tab label="Problem" {...a11yProps(7)} /> 
            <Tab label="ValueProp" {...a11yProps(8)} /> 
            <Tab label="Story" {...a11yProps(9)} /> 
            <Tab label="Sender Title" {...a11yProps(10)} /> 
            <Tab label="Day" {...a11yProps(11)} /> 
            <Tab label="Time" {...a11yProps(12)} /> 
            <Tab label="Length" {...a11yProps(13)} /> 
            <Tab label="Action" {...a11yProps(14)} /> 
          </Tabs>
        </AppBar>
        <TabPanel value={value} className="tagpaddnone whitebg" index={0}>
          <div className="tagpaddnone pxinnerno bg-white"> 
            {/* <div className="row ">
                <div className="col">
                    <h4 className="headertitlebd">Subject</h4>
                </div>
           </div>   */}

            
  
            <div className="row">
              <div className="col"> 
                  <SubjectTable FeatureName={"Subject"} FeatureFieldID={"7"} ToDate={ToDates} FromDate={FromDates} dater={daterange}/>
                </div> 
            </div>

            

          </div>
        </TabPanel>
        
        <TabPanel value={value} className="whitebg" index={1}>  
        <div className="tagpaddnone pxinnerno bg-white"> 
          {/* <div className="row ">
              <div className="col">
                  <h4 className="headertitlebd">Greeting</h4>
              </div>
              
          </div> */}

          

          <div className="row">
              <div className="col"> 
                  <SubjectTable FeatureFieldID={"3"} FeatureName={"Greeting"} ToDate={ToDates} FromDate={FromDates} dater={daterange}/>
                </div> 
            </div>

          

        </div>
        </TabPanel>


        <TabPanel value={value} className="whitebg" index={2}>  
          <div className="tagpaddnone pxinnerno bg-white "> 
            <div className="row ">
                {/* <div className="col">
                    <h4 className="headertitlebd">Opener</h4>
                </div> */}
                {/* <div className="col padright pt-0 pr-0">
                  <div className="listing-li float-right padb-15 iconbox-pad">
                  <ul>  
                        <li>
                          <a href="" className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                            <i class="la la-icon-font-size-13 la-download"></i>
                          </a>
                        </li> 
                      </ul>
                      </div>

                </div> */}
            </div>

           

            <div className="row">
              <div className="col"> 
                  <SubjectTable FeatureFieldID={"5"} FeatureName={"Opener"} ToDate={ToDates} FromDate={FromDates} dater={daterange}/>
                </div> 
            </div>

           

          </div>
        </TabPanel>

        <TabPanel value={value} className="whitebg" index={3}>  
          <div className="tagpaddnone pxinnerno bg-white"> 
            <div className="row ">
                {/* <div className="col">
                    <h4 className="headertitlebd">FUOpener</h4>
                </div> */}
                {/* <div className="col padright pt-0 pr-0">
                  <div className="listing-li float-right padb-15 iconbox-pad">
                      <ul>  
                        <li>
                          <a href="" className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                            <i class="la la-icon-font-size-13 la-download"></i>
                          </a>
                        </li> 
                      </ul>
                      </div>

                </div> */}
            </div>

            {/* <div className="row padt-25">
                <div className="col-sm-12 col-md-6">
                    <label className="textlabelte">Show 
                    <select name="tbl_meeting_length" aria-controls="tbl_meeting" class="form-control form-control-sm">
                    <option value="15">15</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    </select> 
                    entries</label>
                </div>
                <div className="col-sm-12 col-md-6 full-right">
                <label className="textlabelte">Search: <input type="search" class="form-control form-control-sm ml-2" placeholder="" aria-controls="tbl_meeting" /></label>
                </div>
            </div> */}

            <div className="row">
              <div className="col"> 
                  <SubjectTable FeatureFieldID={"6"} FeatureName={"FUOpener"} ToDate={ToDates} FromDate={FromDates} dater={daterange}/>
                </div> 
            </div>

            {/* <div class="row">
              <div class="col dataTables_info">
              <p>Showing 1 to 4 of 4 entries</p>
              </div>
            </div> */}

          </div>
        </TabPanel>
        
        <TabPanel value={value} className="whitebg" index={4}>  
        <div className="tagpaddnone pxinnerno bg-white"> 
            <div className="row ">
                {/* <div className="col">
                    <h4 className="headertitlebd">CTA</h4>
                </div> */}
                {/* <div className="col padright pt-0 pr-0">
                  <div className="listing-li float-right padb-15 iconbox-pad">
                      <ul>  
                        <li>
                          <a href="" className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                            <i class="la la-icon-font-size-13 la-download"></i>
                          </a>
                        </li> 
                      </ul>
                      </div>

                </div> */}
            </div>

            {/* <div className="row padt-25">
                <div className="col-sm-12 col-md-6">
                    <label className="textlabelte">Show 
                    <select name="tbl_meeting_length" aria-controls="tbl_meeting" class="form-control form-control-sm">
                    <option value="15">15</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    </select> 
                    entries</label>
                </div>
                <div className="col-sm-12 col-md-6 full-right">
                <label className="textlabelte">Search: <input type="search" class="form-control form-control-sm ml-2" placeholder="" aria-controls="tbl_meeting" /></label>
                </div>
            </div> */}

            <div className="row">
              <div className="col"> 
                  <SubjectTable FeatureFieldID={"1"} FeatureName={"CTA"} ToDate={ToDates} FromDate={FromDates} dater={daterange}/>
                </div> 
            </div>

            {/* <div class="row">
              <div class="col dataTables_info">
              <p>Showing 1 to 4 of 4 entries</p>
              </div>
            </div> */}

          </div>
        </TabPanel>

        <TabPanel value={value} className="whitebg" index={5}>  
        <div className="tagpaddnone pxinnerno bg-white"> 
            <div className="row ">
                {/* <div className="col">
                    <h4 className="headertitlebd">Closer</h4>
                </div> */}
                {/* <div className="col padright pt-0 pr-0">
                  <div className="listing-li float-right padb-15 iconbox-pad">
                  <ul>  
                        <li>
                          <a href="" className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                            <i class="la la-icon-font-size-13 la-download"></i>
                          </a>
                        </li> 
                      </ul>
                      </div>

                </div> */}
            </div>

            {/* <div className="row padt-25">
                <div className="col-sm-12 col-md-6">
                    <label className="textlabelte">Show 
                    <select name="tbl_meeting_length" aria-controls="tbl_meeting" class="form-control form-control-sm">
                    <option value="15">15</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    </select> 
                    entries</label>
                </div>
                <div className="col-sm-12 col-md-6 full-right">
                <label className="textlabelte">Search: <input type="search" class="form-control form-control-sm ml-2" placeholder="" aria-controls="tbl_meeting" /></label>
                </div>
            </div> */}

          <div className="row">
              <div className="col">
                <div className="table-bordered">
                  <SubjectTable FeatureFieldID={"2"} FeatureName={"Closer"} ToDate={ToDates} FromDate={FromDates} dater={daterange}/>
                </div>
              </div>
            </div>

            {/* <div class="row">
              <div class="col dataTables_info">
              <p>Showing 1 to 4 of 4 entries</p>
              </div>
            </div> */}

          </div>
        </TabPanel>

        <TabPanel value={value} className="whitebg" index={6}>  
        <div className="tagpaddnone pxinnerno bg-white"> 
            <div className="row ">
                {/* <div className="col">
                    <h4 className="headertitlebd">OptOut</h4>
                </div> */}
                {/* <div className="col padright pt-0 pr-0">
                  <div className="listing-li float-right padb-15 iconbox-pad">
                  <ul>  
                        <li>
                          <a href="" className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                            <i class="la la-icon-font-size-13 la-download"></i>
                          </a>
                        </li> 
                      </ul>
                      </div>

                </div> */}
            </div>

            {/* <div className="row padt-25">
                <div className="col-sm-12 col-md-6">
                    <label className="textlabelte">Show 
                    <select name="tbl_meeting_length" aria-controls="tbl_meeting" class="form-control form-control-sm">
                    <option value="15">15</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    </select> 
                    entries</label>
                </div>
                <div className="col-sm-12 col-md-6 full-right">
                <label className="textlabelte">Search: <input type="search" class="form-control form-control-sm ml-2" placeholder="" aria-controls="tbl_meeting" /></label>
                </div>
            </div> */}

            <div className="row">
              <div className="col"> 
                  <SubjectTable FeatureFieldID={"8"} FeatureName={"OptOut"} ToDate={ToDates} FromDate={FromDates} dater={daterange}/>
                </div> 
            </div>

            {/* <div class="row">
              <div class="col dataTables_info">
              <p>Showing 1 to 4 of 4 entries</p>
              </div>
            </div> */}

          </div>
        </TabPanel>

        <TabPanel value={value} className="whitebg" index={7}>  
        <div className="tagpaddnone pxinnerno bg-white"> 
            <div className="row ">
                {/* <div className="col">
                    <h4 className="headertitlebd">Problem</h4>
                </div> */}
                {/* <div className="col padright pt-0 pr-0">
                  <div className="listing-li float-right padb-15 iconbox-pad">
                  <ul>  
                        <li>
                          <a href="" className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                            <i class="la la-icon-font-size-13 la-download"></i>
                          </a>
                        </li> 
                      </ul>
                      </div>

                </div> */}
            </div>

            {/* <div className="row padt-25">
                <div className="col-sm-12 col-md-6">
                    <label className="textlabelte">Show 
                    <select name="tbl_meeting_length" aria-controls="tbl_meeting" class="form-control form-control-sm">
                    <option value="15">15</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    </select> 
                    entries</label>
                </div>
                <div className="col-sm-12 col-md-6 full-right">
                <label className="textlabelte">Search: <input type="search" class="form-control form-control-sm ml-2" placeholder="" aria-controls="tbl_meeting" /></label>
                </div>
            </div> */}

            <div className="row">
              <div className="col"> 
                  <SubjectTable FeatureFieldID={"9"} FeatureName={"Problem"} ToDate={ToDates} FromDate={FromDates} dater={daterange}/>
                </div> 
            </div>

            {/* <div class="row">
              <div class="col dataTables_info">
              <p>Showing 1 to 4 of 4 entries</p>
              </div>
            </div> */}

          </div>
        </TabPanel>

        <TabPanel value={value} className="whitebg" index={8}>  
        <div className="tagpaddnone pxinnerno bg-white"> 
            <div className="row ">
                {/* <div className="col">
                    <h4 className="headertitlebd">ValueProp</h4>
                </div> */}
                {/* <div className="col padright pt-0 pr-0">
                  <div className="listing-li float-right padb-15 iconbox-pad">
                  <ul>  
                        <li>
                          <a href="" className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                            <i class="la la-icon-font-size-13 la-download"></i>
                          </a>
                        </li> 
                      </ul>
                      </div>

                </div> */}
            </div>

            {/* <div className="row padt-25">
                <div className="col-sm-12 col-md-6">
                    <label className="textlabelte">Show 
                    <select name="tbl_meeting_length" aria-controls="tbl_meeting" class="form-control form-control-sm">
                    <option value="15">15</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    </select> 
                    entries</label>
                </div>
                <div className="col-sm-12 col-md-6 full-right">
                <label className="textlabelte">Search: <input type="search" class="form-control form-control-sm ml-2" placeholder="" aria-controls="tbl_meeting" /></label>
                </div>
            </div> */}

            <div className="row">
              <div className="col"> 
                  <SubjectTable FeatureFieldID={"10"} FeatureName={"ValueProp"} ToDate={ToDates} FromDate={FromDates} dater={daterange}/>
                </div> 
            </div>

            {/* <div class="row">
              <div class="col dataTables_info">
              <p>Showing 1 to 4 of 4 entries</p>
              </div>
            </div> */}

          </div>
        </TabPanel>

        <TabPanel value={value} className="whitebg" index={9}>  
        <div className="tagpaddnone pxinnerno bg-white"> 
            <div className="row ">
                {/* <div className="col">
                    <h4 className="headertitlebd">Story</h4>
                </div> */}
                {/* <div className="col padright pt-0 pr-0">
                  <div className="listing-li float-right padb-15 iconbox-pad">
                  <ul>  
                        <li>
                          <a href="" className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                            <i class="la la-icon-font-size-13 la-download"></i>
                          </a>
                        </li> 
                      </ul>
                      </div>

                </div> */}
            </div>

            {/* <div className="row padt-25">
                <div className="col-sm-12 col-md-6">
                    <label className="textlabelte">Show 
                    <select name="tbl_meeting_length" aria-controls="tbl_meeting" class="form-control form-control-sm">
                    <option value="15">15</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    </select> 
                    entries</label>
                </div>
                <div className="col-sm-12 col-md-6 full-right">
                <label className="textlabelte">Search: <input type="search" class="form-control form-control-sm ml-2" placeholder="" aria-controls="tbl_meeting" /></label>
                </div>
            </div> */}

            <div className="row">
              <div className="col"> 
                  <SubjectTable FeatureFieldID={"11"} FeatureName={"Story"} ToDate={ToDates} FromDate={FromDates} dater={daterange}/>
                </div> 
            </div>

            {/* <div class="row">
              <div class="col dataTables_info">
              <p>Showing 1 to 4 of 4 entries</p>
              </div>
            </div> */}

          </div>
        </TabPanel>
        <TabPanel value={value} className="whitebg" index={10}>  
        <div className="tagpaddnone pxinnerno bg-white"> 
            <div className="row ">
                {/* <div className="col">
                    <h4 className="headertitlebd">Sender Title</h4>
                </div> */}
                {/* <div className="col padright pt-0 pr-0">
                  <div className="listing-li float-right padb-15 iconbox-pad">
                  <ul>  
                        <li>
                          <a href="" className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                            <i class="la la-icon-font-size-13 la-download"></i>
                          </a>
                        </li> 
                      </ul>
                      </div>

                </div> */}
            </div>

            {/* <div className="row padt-25">
                <div className="col-sm-12 col-md-6">
                    <label className="textlabelte">Show 
                    <select name="tbl_meeting_length" aria-controls="tbl_meeting" class="form-control form-control-sm">
                    <option value="15">15</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    </select> 
                    entries</label>
                </div>
                <div className="col-sm-12 col-md-6 full-right">
                <label className="textlabelte">Search: <input type="search" class="form-control form-control-sm ml-2" placeholder="" aria-controls="tbl_meeting" /></label>
                </div>
            </div> */}

            <div className="row">
              <div className="col"> 
                  <SubjectTable FeatureFieldID={"4"} FeatureName={"SenderTitle"} ToDate={ToDates} FromDate={FromDates} dater={daterange}/>
                </div> 
            </div>

            {/* <div class="row">
              <div class="col dataTables_info">
              <p>Showing 1 to 4 of 4 entries</p>
              </div>
            </div> */}

          </div>
        </TabPanel>

        <TabPanel value={value} className="whitebg" index={11}>  
        <div className="tagpaddnone pxinnerno bg-white"> 
            <div className="row ">
                {/* <div className="col">
                    <h4 className="headertitlebd">Day</h4>
                </div> */}
                {/* <div className="col padright pt-0 pr-0">
                  <div className="listing-li float-right padb-15 iconbox-pad">
                  <ul>  
                        <li>
                          <a href="" className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                            <i class="la la-icon-font-size-13 la-download"></i>
                          </a>
                        </li> 
                      </ul>
                      </div>

                </div> */}
            </div>

            {/* <div className="row padt-25">
                <div className="col-sm-12 col-md-6">
                    <label className="textlabelte">Show 
                    <select name="tbl_meeting_length" aria-controls="tbl_meeting" class="form-control form-control-sm">
                    <option value="15">15</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    </select> 
                    entries</label>
                </div>
                <div className="col-sm-12 col-md-6 full-right">
                <label className="textlabelte">Search: <input type="search" class="form-control form-control-sm ml-2" placeholder="" aria-controls="tbl_meeting" /></label>
                </div>
            </div> */}

          <div className="row">
              <div className="col"> 
                  <SubjectTable FeatureFieldID={"1"} FeatureName={"Day"} ToDate={ToDates} FromDate={FromDates} dater={daterange}/>
                </div> 
            </div>

            {/* <div class="row">
              <div class="col dataTables_info">
              <p>Showing 1 to 4 of 4 entries</p>
              </div>
            </div> */}

          </div>
        </TabPanel>

        <TabPanel value={value} className="whitebg" index={12}>  
        <div className="tagpaddnone pxinnerno bg-white"> 
            <div className="row ">
                {/* <div className="col">
                    <h4 className="headertitlebd">Time</h4>
                </div> */}
                {/* <div className="col padright pt-0 pr-0">
                  <div className="listing-li float-right padb-15 iconbox-pad">
                  <ul>  
                        <li>
                          <a href="" className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                            <i class="la la-icon-font-size-13 la-download"></i>
                          </a>
                        </li> 
                      </ul>
                      </div>

                </div> */}
            </div>

            {/* <div className="row padt-25">
                <div className="col-sm-12 col-md-6">
                    <label className="textlabelte">Show 
                    <select name="tbl_meeting_length" aria-controls="tbl_meeting" class="form-control form-control-sm">
                    <option value="15">15</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    </select> 
                    entries</label>
                </div>
                <div className="col-sm-12 col-md-6 full-right">
                <label className="textlabelte">Search: <input type="search" class="form-control form-control-sm ml-2" placeholder="" aria-controls="tbl_meeting" /></label>
                </div>
            </div> */}

            <div className="row">
              <div className="col"> 
                  <SubjectTable FeatureFieldID={"2"} FeatureName={"Time"} ToDate={ToDates} FromDate={FromDates} dater={daterange}/>
                </div> 
            </div>

            {/* <div class="row">
              <div class="col dataTables_info">
              <p>Showing 1 to 4 of 4 entries</p>
              </div>
            </div> */}

          </div>
        </TabPanel>


        <TabPanel value={value} className="whitebg" index={13}>  
        <div className="tagpaddnone pxinnerno bg-white"> 
            <div className="row ">
                {/* <div className="col">
                    <h4 className="headertitlebd">Length</h4>
                </div> */}
                {/* <div className="col padright pt-0 pr-0">
                  <div className="listing-li float-right padb-15 iconbox-pad">
                  <ul>  
                        <li>
                          <a href="" className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                            <i class="la la-icon-font-size-13 la-download"></i>
                          </a>
                        </li> 
                      </ul>
                      </div>

                </div> */}
            </div>

            {/* <div className="row padt-25">
                <div className="col-sm-12 col-md-6">
                    <label className="textlabelte">Show 
                    <select name="tbl_meeting_length" aria-controls="tbl_meeting" class="form-control form-control-sm">
                    <option value="15">15</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    </select> 
                    entries</label>
                </div>
                <div className="col-sm-12 col-md-6 full-right">
                <label className="textlabelte">Search: <input type="search" class="form-control form-control-sm ml-2" placeholder="" aria-controls="tbl_meeting" /></label>
                </div>
            </div> */}

          <div className="row">
              <div className="col"> 
                  <SubjectTable FeatureFieldID={"3"} FeatureName={"Length"} ToDate={ToDates} FromDate={FromDates} dater={daterange}/>
                </div> 
            </div>

            {/* <div class="row">
              <div class="col dataTables_info">
              <p>Showing 1 to 4 of 4 entries</p>
              </div>
            </div> */}

          </div>
        </TabPanel>


        <TabPanel value={value} className="whitebg" index={14}>  
        <div className="tagpaddnone pxinnerno bg-white"> 
            <div className="row ">
                {/* <div className="col">
                    <h4 className="headertitlebd">Action</h4>
                </div> */}
                {/* <div className="col padright pt-0 pr-0">
                  <div className="listing-li float-right padb-15 iconbox-pad">
                  <ul>  
                        <li>
                          <a href="" className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                            <i class="la la-icon-font-size-13 la-download"></i>
                          </a>
                        </li> 
                      </ul>
                      </div>

                </div> */}
            </div>

            {/* <div className="row padt-25">
                <div className="col-sm-12 col-md-6">
                    <label className="textlabelte">Show 
                    <select name="tbl_meeting_length" aria-controls="tbl_meeting" class="form-control form-control-sm">
                    <option value="15">15</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    </select> 
                    entries</label>
                </div>
                <div className="col-sm-12 col-md-6 full-right">
                <label className="textlabelte">Search: <input type="search" class="form-control form-control-sm ml-2" placeholder="" aria-controls="tbl_meeting" /></label>
                </div>
            </div> */}

            <div className="row">
              <div className="col"> 
                  <SubjectTable FeatureFieldID={"4"} FeatureName={"Action"} ToDate={ToDates} FromDate={FromDates} dater={daterange}/>
                </div> 
            </div>

            {/* <div class="row">
              <div class="col dataTables_info">
              <p>Showing 1 to 4 of 4 entries</p>
              </div>
            </div> */}

          </div>
        </TabPanel>
 
       
      </div>
 
    </div>
  );
}