import React, { useEffect } from "react";
import Axios from "axios";
const moment = require("moment");
import Swal from "sweetalert2";
import Pagination from "@material-ui/lab/Pagination";
import { CSVReader, CSVDownloader } from "react-papaparse";

import PropTypes from "prop-types";
import parse from "html-react-parser";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { Avatar } from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import Userimg from "../../../images/avatar/1.jpg";
import userdefault from "../../../images/default.png";

import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import Popup from "reactjs-popup";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import loadingicon from "../../../images/loading.gif";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import StyleHeader from "../StickyHeader/StickyHeader";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

function createData(name, calories, fat, carbs, protein, price) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    price,
    history: [
      { date: "2020-01-05", customerId: "11091700", amount: 3 },
      { date: "2020-01-02", customerId: "Anonymous", amount: 1 },
    ],
  };
}

// function Row(props) {
//   const { row } = props;
//   const [open, setOpen] = React.useState(false);
//   const classes = useRowStyles();

//   const [anchorEl, setAnchorEl] = React.useState(null);
//   const [popupval, setpopup] = React.useState(false);
//   const [cancelpopupval, setcancelpopup] = React.useState(false);
//   const [ArchivedPopup, setarchivedpopup] = React.useState(false);

//   const handleClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   const openEditPopup = () => {
//     setpopup(true);
//   };

//   const closeTermsConditionModal = () => {
//     setpopup(false);
//   };

//   const SubmitTermsConditionModal = () => {
//     setpopup(false);
//   };

//   const openCencelPopup = () => {
//     setcancelpopup(true);
//   };

//   const openArchivedPopup = () => {
//     setarchivedpopup(true);
//   };

//   document.addEventListener("mouseup", function (e) {
//     //console.log(popupval);
//     if (popupval) {
//       setpopup(false);
//       setcancelpopup(false);
//       setarchivedpopup(false);
//     }
//   });

//   //const openTermsConditionPopup = false;

//   return (
//     <>
//       {/* <a onClick={openEditPopup} className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
//           <i class="fa la-icon-font-size-13 fa-filter"></i>
//         </a>  */}

//       <Popup open={popupval} modal>
//         <div className="modal-black"></div>
//         <div className="filterPopup maxpopup">
//           <div className="paddingboxTerms">
//             <div className="modal-content bordernone aligncentertext">
//               <div class="swal2-icon swal2-warning swal2-animate-warning-icon">
//                 <span class="swal2-icon-text">!</span>
//               </div>
//               <h3>Are you sure?</h3>
//               <p>you want to Archive a Responder.</p>
//               <div class="swal2-actions">
//                 <button
//                   type="button"
//                   class="swal2-cancel btn btn-secondary m-btn m-btn--custom mr-2"
//                 >
//                   No, cancel!
//                 </button>
//                 <button
//                   type="button"
//                   class="swal2-confirm btn btn-success m-btn m-btn--custom"
//                 >
//                   Yes, archive it!
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//       </Popup>

//       {/* <a onClick={openCencelPopup} className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
//            <i class="fa la-icon-font-size-13 fa-filter"></i>
//         </a>  */}

//       <Popup open={cancelpopupval} modal>
//         <div className="modal-black"></div>
//         <div className="filterPopup maxpopup">
//           <div className="paddingboxTerms">
//             <div className="modal-content bordernone aligncentertext">
//               <div class="swal2-icon swal2-error swal2-animate-error-icon d-flex">
//                 <span class="swal2-x-mark">
//                   <span class="swal2-x-mark-line-left"></span>
//                   <span class="swal2-x-mark-line-right"></span>
//                 </span>
//               </div>
//               <h3>Cancelled</h3>
//               <p>Your data is safe :)</p>
//               <div class="swal2-actions">
//                 {/* <button type="button" class="swal2-cancel btn btn-secondary m-btn m-btn--custom mr-2">No, cancel!</button> */}
//                 <button
//                   type="button"
//                   class="swal2-confirm btn btn-success m-btn m-btn--custom"
//                 >
//                   Ok
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//       </Popup>

//       {/* <a onClick={openArchivedPopup} className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
//            <i class="fa la-icon-font-size-13 fa-filter"></i>
//         </a>  */}

//       <Popup open={ArchivedPopup} modal>
//         <div className="modal-black"></div>
//         <div className="filterPopup maxpopup">
//           <div className="paddingboxTerms">
//             <div className="modal-content bordernone aligncentertext">
//               <div class="swal2-icon swal2-success swal2-animate-success-icon">
//                 <div class="swal2-success-circular-line-left"></div>
//                 <span class="swal2-success-line-tip"></span>
//                 <span class="swal2-success-line-long"></span>
//                 <div class="swal2-success-ring"></div>
//                 <div class="swal2-success-fix"></div>
//                 <div class="swal2-success-circular-line-right"></div>
//               </div>
//               <h3>Archived!</h3>
//               <p>Responder Archived successfully.</p>
//               <div class="swal2-actions">
//                 {/* <button type="button" class="swal2-cancel btn btn-secondary m-btn m-btn--custom mr-2">No, cancel!</button> */}
//                 <button
//                   type="button"
//                   class="swal2-confirm btn btn-success m-btn m-btn--custom"
//                 >
//                   Ok
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//       </Popup>

//       <React.Fragment>
//         <TableRow className={classes.root}>
//           {/*          
//         <TableCell>
//         <div class="toggleswich">
//           <input type="checkbox" class="checktogle" />
//           <b class="switch"> 
//             <span class="checkion"></span>
//             <span class="uncheckion"></span>
//           </b>
//           <b class="track"></b>
//         </div>
//         </TableCell> */}
//           <TableCell>Client.Company Intro </TableCell>
//           <TableCell>0</TableCell>
//           <TableCell>0</TableCell>
//           <TableCell>0</TableCell>
//           <TableCell>0</TableCell>
//           <TableCell>0 %</TableCell>
//           <TableCell>0 %</TableCell>
//           <TableCell>0 %</TableCell>
//           <TableCell>0 %</TableCell>
//         </TableRow>

//         <TableRow>
//           <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
//             <Collapse in={open} timeout="auto" unmountOnExit>
//               <Box margin={1} className="innertables">
//                 <Table size="small" aria-label="purchases">
//                   <TableHead></TableHead>

//                   <TableRow>
//                     <TableCell>8 June 2021</TableCell>
//                     <TableCell> </TableCell>
//                     <TableCell> </TableCell>
//                   </TableRow>
//                 </Table>
//               </Box>
//             </Collapse>
//           </TableCell>
//         </TableRow>
//       </React.Fragment>
//     </>
//   );
// }

// Row.propTypes = {
//   row: PropTypes.shape({
//     Userimg: PropTypes.number.isRequired,
//     calories: PropTypes.number.isRequired,
//     carbs: PropTypes.number.isRequired,
//     fat: PropTypes.number.isRequired,
//     history: PropTypes.arrayOf(
//       PropTypes.shape({
//         amount: PropTypes.number.isRequired,
//         customerId: PropTypes.string.isRequired,
//         date: PropTypes.string.isRequired,
//       })
//     ).isRequired,
//     name: PropTypes.string.isRequired,
//     price: PropTypes.number.isRequired,
//     protein: PropTypes.number.isRequired,
//   }).isRequired,
// };

// const rows = [
//   createData("Frozen yoghurt", 159, 6.0, 24, 4.0, 3.99),
//   createData("Ice cream sandwich", 237, 9.0, 37, 4.3, 4.99),
//   createData("Eclair", 262, 16.0, 24, 6.0, 3.79),
//   createData("Eclair", 262, 16.0, 24, 6.0, 3.79),
//   createData("Ice cream sandwich", 237, 9.0, 37, 4.3, 4.99),
//   createData("Eclair", 262, 16.0, 24, 6.0, 3.79),
// ];

export default function AccountAllTable({
  ToDate,
  FromDate,
  dater,
  FeatureName,
  FeatureFieldID,
}) {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("CreatedDate");
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [FromDates, SetFromDates] = React.useState(FromDate);
  const [ToDates, SetToDates] = React.useState(ToDate);
  const [ExportData, SetExportData] = React.useState([]);
  const [ClientName, SetClientName] = React.useState("");
  const [IsLoading, SetIsLoading] = React.useState(false);
  const [sortedColumn, setSortedColumn] = React.useState(null);
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);

  useEffect(() => {
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }
    SetFromDates(FromDate);
    SetToDates(ToDate);
    ExportDataMethod(Details.ClientID,Details.ParentUserID,dater);
    VariableReporting(
      Details.ClientID,
      Details.ParentUserID,
      Details.Role,
      dater
    );
  }, [Search, Page, RowsPerPage, ToDate, FromDate, dater]);

  //Export Data
  const ExportDataMethod = (CID, UID, DateRange) => {
    const InputParameters = {
      ClientID: CID,
      UserID: UID,
      ToDate: ToDates,
      FromDate: FromDates,
      FieldName: FeatureName,
      FeatureFieldID: FeatureFieldID,
      DateRange: DateRange,
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/variable_reporting/VariableReprotingExport",
      method: "POST",
      data: InputParameters,
    }).then((Res) => {
      SetClientName(Res.data.ClientName[0]?.Name);
      SetExportData(Res.data.Data);
    });
  };

  const ExportCsv = () => {};

  // Variable Reporting get list
  const VariableReporting = (CID, UID, URole, DateRange) => {
    SetIsLoading(true)
    var InputParameters = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
      ToDate: ToDates,
      FromDate: FromDates,
      FieldName: FeatureName,
      FeatureFieldID: FeatureFieldID,
      DateRange: DateRange,
    };
    const rows1 = Axios({
      url:
        CommonConstants.MOL_APIURL + "/variable_reporting/VariableReportingGet",
      method: "POST",
      data: InputParameters,
    });
    rows1.then((Result) => {
      console.log(Result, "ss1");
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetIsLoading(false)
      setIsDataLoaded(true);

    });
  };

  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      SetSearch(SearchedVal);
      SetPage(1);
      //document.getElementById("hideloding").style.display = "block";
    }
  };

  //change Page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
     // document.getElementById("hideloding").style.display = "block";
    }
  };

  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
   // document.getElementById("hideloding").style.display = "block";
  };

  //get sort field data
  const SortData = (Field) => {
    SetIsLoading(true)
    var SearchedVal = document.getElementById("search").value;
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetSflag(false);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        SerchBox: false,
        Archive: false,
        Search: SearchedVal,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
        ToDate: ToDates,
        FromDate: FromDates,
        FieldName: FeatureName,
        FeatureFieldID: FeatureFieldID,
        DateRange: dater,
      };
    //  console.log(InputParameter);
      const AccountCategoriesList = Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/variable_reporting/VariableReportingGet",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        console.log(Result.data);
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetIsLoading(false)
      });
    } else {
      SerchBox = true;
      SetSflag(true);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Search: SearchedVal,
        SerchBox: SerchBox,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
        ToDate: ToDates,
        FromDate: FromDates,
        FieldName: FeatureName,
        FeatureFieldID: FeatureFieldID,
        DateRange: dater,
      };
      // console.log(InputParameter);
      const AccountCategoriesList = Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/variable_reporting/VariableReportingGet",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetIsLoading(false)
      });
    }
  };
  return (
    <>
    {IsLoading ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : (
        <></>
      )}
      <div className="row mx-0">
        <div className="col-md-6 border-bottom">
          <h4 className="headertitlebd xs-headertitle ">{FeatureName}</h4>
        </div>
        <div className="col border-bottom">
          <div className="listing-li float-right padb-15 px-0">
            <ul>
            <Tooltip title="Export">
            <a
                  onClick={ExportCsv}
                  className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                >
              <li>
    
                  <span>
                    <CSVDownloader className="px-0"
                      data={ExportData}
                      filename={`${ClientName}-Subject`}
                      bom={true}
                    >
                      <i class="la la-icon-font-size-13 la-download"></i>
                    </CSVDownloader>
                  </span>
              </li>
              </a>
              </Tooltip>
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col padd-0"></div>
      </div>
      <div className="row pt-3 mx-0">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              class="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label class="textlabelte">
            Search:
            <input
              type="Search"
              id="search"
              onKeyPress={RequestSearch}
              class="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      
      <div className="table-bordered border-0  px-3">
      <TableContainer component={Paper}>
      <StyleHeader isDataLoaded={isDataLoaded} />

          <Table className="table-ref"  aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell onClick={() => {
                  SortData("FeatureValue");
                  setSortedColumn("FeatureValue"); // Set the sorted column
                }}>
                {/* <a
                  onClick={() => {
                    SortData("FeatureValue");
                  }}
                > */}
        
                  Subject
                  <span className="shorting">
                         <ArrowUpward className={SortedBy === 1 && sortedColumn === "FeatureValue" ? "active" : null} />
                          <ArrowDownward  className={SortedBy === -1 && sortedColumn === "FeatureValue"  ? "active" : null} />
                          </span>
                {/* </a> */}
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("FeatureValue");
                  }}
                >
                  Sent
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("FeatureValue");
                  }}
                >
                  Tracked
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("FeatureValue");
                  }}
                >
                  Replies
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("FeatureValue");
                  }}
                >
                  Interested
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("FeatureValue");
                  }}
                >
                  Open %
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("FeatureValue");
                  }}
                >
                  Reply %
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("FeatureValue");
                  }}
                >
        Interested % 
                </a>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Rows.map((row) => (
              <TableRow key={row.name} row={row}>
                <TableCell>
                  {parse(row?.FeatureValue.replace(/<[^>]+>/g, ""))}
                </TableCell>
                <TableCell>{row?.Sent}</TableCell>
                <TableCell>{row?.Tracked}</TableCell>
                <TableCell>{row?.Reply}</TableCell>
                <TableCell>{row?.Intrested}</TableCell>
                <TableCell>
                  <Tooltip
                    title={
                      <>
                        <span>
                          Opens: {row?.Open === undefined ? 0 : row?.Open}
                        </span>
                        <br />
                        <span>
                          Tracked:{" "}
                          {row?.Tracked === undefined ? 0 : row?.Tracked}
                        </span>
                        <br />
                        <span>
                          Open %:{" "}
                          {row?.Tracked === 0
                            ? 0 + "%"
                            : parseInt(
                                (row?.Open === undefined
                                  ? 0
                                  : row?.Open / row?.Tracked) * 100
                              ) + "%"}
                        </span>
                      </>
                    }
                    placement="right"
                  >
                    <span>
                      {row?.Tracked === 0
                        ? 0 + "%"
                        : parseInt(
                            (row?.Open === undefined
                              ? 0
                              : row?.Open / row?.Tracked) * 100
                          ) + "%"}
                    </span>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip
                    title={
                      <>
                        <span>
                          Replies: {row?.Reply === undefined ? 0 : row?.Reply}
                        </span>
                        <br />
                        <span>
                          Sent: {row?.Sent === undefined ? 0 : row?.Sent}
                        </span>
                        <br />
                        <span>
                          Reply %:{" "}
                          {row?.Sent === 0
                            ? 0 + "%"
                            : parseInt((row?.Reply / row?.Sent) * 100) + "%"}
                        </span>
                      </>
                    }
                    placement="right"
                  >
                    <span>
                      {row?.Sent === 0
                        ? 0 + "%"
                        : parseInt((row?.Reply / row?.Sent) * 100) + "%"}
                    </span>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip
                    title={
                      <>
                        <span>
                          Replies: {row?.Reply === undefined ? 0 : row?.Reply}
                        </span>
                        <br />
                        <span>
                          Interested:{" "}
                          {row?.Intrested === undefined ? 0 : row?.Intrested}
                        </span>
                        <br />
                        <span>
                          Interested %:{" "}
                          {row?.Reply === 0
                            ? 0 + "%"
                            : parseInt((row?.Intrested / row?.Reply) * 100) +
                              "%"}
                        </span>
                      </>
                    }
                    placement="right"
                  >
                    <span>
                      {row?.Reply === 0
                        ? 0 + "%"
                        : parseInt((row?.Intrested / row?.Reply) * 100) + "%"}
                    </span>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      </div>
      {Sflag ? (
        <div class="row mx-0 pb-3">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div class="row mx-0 pb-3">
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div class="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </>
  );
}
