import React from "react";
import { connect } from "react-redux";
import Axios from "axios";
import Swal from "sweetalert2";
import Popup from "reactjs-popup";
import { toast } from "react-toastify";
const Moment = require("moment");
import "react-toastify/dist/ReactToastify.css";

//import CallHistory from '../_components/coldcalling/callhistory';
//import CollapsibleTable from '../_components/usertable';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";
import ReactApexchart from "react-apexcharts";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';

import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import loadingicon from "../../../images/loading.gif";

const moment = require("moment");
class ViewBookedMeetingPage extends React.Component {
  constructor(props) {
    super(props);

    const idFromState = props.location?.state?.data;

    // If location.state.data doesn't exist, try to get ID from the URL query string
    const urlParams = new URLSearchParams(window.location.search);
    const idFromQueryString = urlParams.get("ID");
    const idFromQueryStringName = urlParams.get("pagename");

    this.state = {
      fields: {},
      errors: {},
      fields1: {},
      errors1: {},
      statusAvailable: null,
      showing: true,
      meetingsource: [],
      meetingowner: [],
      meetingstatus: [],
      allres: [],
      sourcemeeting: "",
      StatusMeeting: "",
      statusmeeting: [],
      timezone: "",
      seniority: "",
      meetingowner: "",
      Accountemail: "",
      meetingsetbyname: "",
      data: [],
      rows: [],
      rlen: 0,
      flen: 0,
      countpage: 0,
      data1: [],
      rows1: [],
      rlen1: 0,
      flen1: 0,
      countpage1: 0,
      ClientID: null,
      Notesid:null,
      Role: null,
      UserID: null,
      CUserID: null,
      page: 1,
      rowsPerPage: 100,
      search: "",
      sortField: "Title",
      sortedBy: 1,
      sflag: false,
      page1: 1,
      rowsPerPage1: 100,
      search1: "",
      sortField1: "ChangeDate",
      HistoryStatusSortedColumn:"ChangeDate",
      sortedBy1: 1,
      sflag1: false,
      meetingid: "",
      meetingCustomField: [],
      meetingCustomfieldbyid: [],
      TitleAvailable: null,
      OldTitleUpdate: null,
      ShowNotePopup: false,
      ShowNotePopupUpdate: false,
      BtnDisabledSave: false,
      BtnDisabledUpdate: false,
      NoteSortedColumn:"Title",
      IsLoading: false,
      StatusDateDetails:[],
      MeetingPageName: this.props.location?.state?.Pagename || idFromQueryStringName || null,
      id: idFromState || idFromQueryString || null,
    };
    this.backbtn = this.backbtn.bind(this);
    this.editbtn = this.editbtn.bind(this);
    this.editspage = this.editspage.bind(this);
    this.saveupdatebtn = this.saveupdatebtn.bind(this);
    this.savebtn = this.savebtn.bind(this);
    this.DeleteClientName = this.DeleteClientName.bind(this);
    this.Handlevalidationfornotesedit =
      this.Handlevalidationfornotesedit.bind(this);
    this.Handlevalidationfornotesadd =
      this.Handlevalidationfornotesadd.bind(this);
    this.EditBookeMeeting();
    this.GetBookedmeeting();
    this.NotesGet();
    this.MeetingHistoryGet();
  }
  componentDidMount() {
    document.title = `Edit Meeting | SalesHive`;
    // this.GetPotentialmeeting();
    var Details = GetUserDetails();

    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.CUserID = Details.ChildUserID;
      this.state.Role = Details.Role;
    }
    this.setState({ IsLoading: true });
  
  }

  EditBookeMeeting() {
    try {
      var str_in = {
        id: this.state.id,
        Role: this.state.Role,
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/bookedmeeting/BookedMeetingByID",
        method: "POST",
        data: str_in,
      })
        .then((res) => {
          this.setState({ allres: res.data?.data });
          //meetingSource
          var str_in1 = {
            MeetingSourceID: res.data.data[0]?.MeetingSourceID,
            MeetingStatusID: res.data.data[0]?.StatusID,
            Role: this.state.Role,
          };
          Axios({
            url:
              CommonConstants.MOL_APIURL +
              "/bookedmeeting/FindBookedMeetingSource",
            method: "POST",
            data: str_in1,
          })
            .then((resmeeting) => {
              this.setState({
                sourcemeeting: resmeeting.data.meetingsource[0]?.Name,
              });
              this.setState({
                StatusMeeting: resmeeting.data.meetingstatus[0]?.Status,
              });
            })
            .catch((error) => {
              //toast.error("An error occurred. Please try again.");
              this.setState({ IsLoading: false });
            });

          //MeetingTimezone
          var str_in4 = {
            TimeZoneID: res.data.data[0]?.TimeZoneID,
            Role: this.state.Role,
          };
          Axios({
            url:
              CommonConstants.MOL_APIURL + "/bookedmeeting/GetTimezoneMeeting",
            method: "POST",
            data: str_in4,
          })
            .then((timezoneres) => {
              this.setState({ timezone: timezoneres.data[0]?.FieldName });
            })
            .catch((error) => {
              //toast.error("An error occurred. Please try again.");
              this.setState({ IsLoading: false });
            });

          //MeetingSeniorty
          var str_in5 = {
            SeniorityID: res.data?.data[0]?.SeniorityID,
            Role: this.state.Role,
          };
          Axios({
            url:
              CommonConstants.MOL_APIURL + "/bookedmeeting/GetMeetingSeniority",
            method: "POST",
            data: str_in5,
          })
            .then((seniorityres) => {
              if (seniorityres.data != null) {
                this.setState({ seniority: seniorityres?.data[0]?.FieldName });
              }
            })
            .catch((error) => {
             // toast.error("An error occurred. Please try again.");
              this.setState({ IsLoading: false });
            });

          //owner
          var str_in6 = {
            MeetingOwnerID: res.data.data[0].MeetingOwnerID,
            ClientID: this.state.ClientID,
            Role: this.state.Role,
          };
          Axios({
            url:
              CommonConstants.MOL_APIURL +
              "/bookedmeeting/FindBookedMeetingOwner",
            method: "POST",
            data: str_in6,
          })
            .then((resmeeting) => {
              this.setState({ meetingowner: resmeeting.data[0]?.Name });
            })
            .catch((error) => {
             // toast.error("An error occurred. Please try again.");
              this.setState({ IsLoading: false });
            });

          //custom field
          var str_in9 = {
            CustomFieldID: res.data.data[0]?._id,
            Role: this.state.Role,
          };
          Axios({
            url:
              CommonConstants.MOL_APIURL +
              "/bookedmeeting/MeetingCustomFieldId",
            method: "POST",
            data: str_in9,
          })
            .then((resmeeting) => {
              //
              this.setState({
                meetingCustomfieldbyid: resmeeting.data.meetingcustomfieldByid,
              });
            })
            .catch((error) => {
             // toast.error("An error occurred. Please try again.");
              this.setState({ IsLoading: false });
            });

          //reminder email
          var str_in7 = {
            ReminderEmailAccountID: res.data.data[0]?.ReminderEmailAccountID,
            Role: this.state.Role,
          };
          Axios({
            url:
              CommonConstants.MOL_APIURL + "/bookedmeeting/GetAccountMeeting",
            method: "POST",
            data: str_in7,
          })
            .then((resmeeting) => {
              this.setState({
                Accountemail: resmeeting.data[0]?.SMTPFromEmail,
              });
            })
            .catch((error) => {
             // toast.error("An error occurred. Please try again.");
              this.setState({ IsLoading: false });
            });

          //setbymeeting

          if (res.data.data[0].ResponderColdCallersID != null) {
            if (res.data.data[0].ResponderColdCallersType == "R") {
              var str_in8 = {
                ResponderColdCallersID:
                  res.data.data[0]?.ResponderColdCallersID.substring(1),
                Role: this.state.Role,
              };
              Axios({
                url:
                  CommonConstants.MOL_APIURL +
                  "/bookedmeeting/FindBookedMeetingsetbyResponder",
                method: "POST",
                data: str_in8,
              })
                .then((resmeetingsetby) => {
                  this.setState({
                    meetingsetbyname: (resmeetingsetby.data[0]?.ResponderName ?? "") + " " + (resmeetingsetby.data[0]?.LastName ?? ""),
                  });
                  
                })
                .catch((error) => {
                 // toast.error("An error occurred. Please try again.");
                  this.setState({ IsLoading: false });
                });
            } else {
              var str_in8 = {
                ResponderColdCallersID:
                  res.data.data[0]?.ResponderColdCallersID.substring(1),
                Role: this.state.Role,
              };
              Axios({
                url:
                  CommonConstants.MOL_APIURL +
                  "/bookedmeeting/FindBookedMeetingsetbyColdcaller",
                method: "POST",
                data: str_in8,
              })
                .then((resmeetingsetby) => {
                  this.setState({
                    meetingsetbyname: (resmeetingsetby.data[0]?.ColdCallersName ?? "") + " " + (resmeetingsetby.data[0]?.LastName ?? ""),
                  });
                  
                })
                .catch((error) => {
                 // toast.error("An error occurred. Please try again.");
                  this.setState({ IsLoading: false });
                });
            }
          }
          // notes
          this.setState({ meetingid: res.data.data[0]._id });
          this.setState({ IsLoading: false });
        })
        .catch((error) => {
         // toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
        });
    } catch (error) {
      //toast.error("An error occurred. Please try again.");
      this.setState({ IsLoading: false });
    }
  }

  MeetingHistoryGet = () => {
    try {
      // Meeting status history
      var Details = GetUserDetails();
      this.setState({ IsLoading: true });
      var str_in3 = {
        ClientID: Details.ClientID,
        EventID: this.state.id,
        Page: this.state.page1,
        RowsPerPage: this.state.rowsPerPage1,
        Sort: true,
        Field: this.state.sortField1,
        Sortby: this.state.sortedBy1,
        Search: this.state.search1,
        Type: "User",
        Role: Details.Role,
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/bookedmeeting/GetBookedlMeetingStatusHistory",
        method: "POST",
        data: str_in3,
      })
        .then((reshistory) => {
          if (reshistory === null) {
            this.setState({ data1: null });
            this.setState({ rows1: null });
            this.setState({ rlen1: null });
            this.setState({ flen1: null });
            this.setState({ countpage1: null });
          } else {

         // Filter and prioritize unique status values
          const uniqueStatus = ["Qualified", "Opportunity", "Closed Won", "Closed Lost", "Unqualified"];
          const filteredData = reshistory.data.pagedata.filter(item => uniqueStatus.includes(item.NewStatus));
          const prioritizedData = [];
          uniqueStatus.forEach(status => {
            const foundItem = filteredData.find(item => item.NewStatus === status);
            if (foundItem) {
              prioritizedData.push(foundItem);
            }
          });
          this.setState({ StatusDateDetails: prioritizedData });
            this.setState({ data1: reshistory.data.pagedata });
            this.setState({ rows1: reshistory.data.pagedata });
            this.setState({ rlen1: reshistory.data.totalcount });
            this.setState({ flen1: reshistory.data.totalcount });
            this.setState({ countpage1: reshistory.data.pagecount });
          }
          this.setState({ IsLoading: false });
          // this.setState({ statusmeeting: reshistory.data.meetingstatus });
        })
        .catch((error) => {
        //  toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
        });
    } catch (error) {
      // toast.error("An error occurred. Please try again.");
      this.setState({ IsLoading: false });
    }
  };

  NotesGet = () => {
    try {
      var Details = GetUserDetails();
      this.setState({ IsLoading: true });
      var str_in = {
        ClientID: Details.ClientID,
        //MeetingID: this.props.location.state.data,
        MeetingID:this.state.id,
        Page: this.state.page,
        RowsPerPage: this.state.rowsPerPage,
        Sort: true,
        Field: this.state.sortField,
        Sortby: this.state.sortedBy,
        Search: this.state.search,
        Type: "User",
        Role: Details.Role,
      };
      const rows1 = Axios({
        url:
          CommonConstants.MOL_APIURL + "/bookedmeeting/GetBookedlMeetingNotes",
        method: "POST",
        data: str_in,
      });
      rows1
        .then((resnotes) => {
          if (resnotes === null) {
            this.setState({ data: null });
            this.setState({ rows: null });
            this.setState({ rlen: null });
            this.setState({ flen: null });
            this.setState({ countpage: null });
          } else {
            this.setState({ data: resnotes.data.pagedata });
            this.setState({ rows: resnotes.data.pagedata });
            this.setState({ rlen: resnotes.data.totalcount });
            this.setState({ flen: resnotes.data.totalcount });
            this.setState({ countpage: resnotes.data.pagecount });
          }
          this.setState({ IsLoading: false });
        })
        .catch((error) => {
          //toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
        });
    } catch (error) {
     // toast.error("An error occurred. Please try again.");
      this.setState({ IsLoading: false });
    }
  };

  backbtn() {
    // const pagename =
    //   this.props.location.state && this.props.location.state["Pagename"];

    if (this.state.MeetingPageName === "Meeting") {
      history.push({
        pathname: "/viewcontacts",
        state: {
          data: this.props.location?.state?.pid,
          EmailData: this.props.location?.state?.EmailData,
        },
      });
    } else if (this.state.MeetingPageName === "ClientMeeting") {
      history.push("/clientmeetings");
    }else if (this.state.MeetingPageName === "allClientReschedule") {
      history.push("/clientrescheduling");
    } else if (this.state.MeetingPageName === "Rescheduling") {
      history.push("/reschedules");
    }else if (this.state.MeetingPageName === "oppotunities" ) {
      history.push("/opportunities");
    } else if (this.state.MeetingPageName === "kpiMeeting") {
      history.push({
        pathname: "/kpis",
        state: {
          data: 2,
        },
      });
    } else if (this.state.MeetingPageName === "AccountContact"){
      history.push({ pathname: "/viewaccount", state: { data: this.props.location?.state?.AccountData } });
    }else {
      history.push("/bookedmeetings");
      // history.push({ pathname: "/bookedmeetings", state: { data: id, Pagename: "" } });
    }
  }

  editbtn = (id) => {
    // const pagename =
    //   this.props.location.state && this.props.location.state["Pagename"];

    if (this.state.MeetingPageName === "Meeting") {
      history.push({
        pathname: "/editbookedmeeting",
        state: {
          data: id,
          Pagename: "Meeting",
          dataprospect: this.props.location?.state?.pid,
          EmailData:this.props.location?.state?.EmailData
        },
      });
    } else if (this.state.MeetingPageName === "ClientMeeting") {
      history.push({
        pathname: "/editbookedmeeting",
        state: { data: id, Pagename: "ClientMeeting" },
      });
    }else if (this.state.MeetingPageName === "allClientReschedule") {
      history.push({
        pathname: "/editbookedmeeting",
        state: { data: id, Pagename: "allClientReschedule" },
      });
    }
     else if (
      this.state.MeetingPageName === "oppotunities" ||
      this.state.MeetingPageName === "Rescheduling" ||
      this.state.MeetingPageName === "kpiMeeting"
    ) {
      history.push({
        pathname: "/editbookedmeeting",
        state: { data: id, Pagename: this.state.MeetingPageName },
      });
    } else {
      history.push({
        pathname: "/editbookedmeeting",
        state: { data: id, Pagename: "" },
      });
    }
  };

  //View booked meeting Notes
  handleChangePage = (event, newPage) => {
    try {
      this.setState({ page: newPage });
      var str_in = {
        ClientID: this.state.ClientID,
        MeetingID: this.state.meetingid,
        Page: this.state.page,
        RowsPerPage: this.state.rowsPerPage,
        Sort: true,
        Field: this.state.sortField,
        Sortby: this.state.sortedBy,
        Search: this.state.search,
        Type: "User",
        Role: this.state.Role,
      };
      const rows1 = Axios({
        url:
          CommonConstants.MOL_APIURL + "/bookedmeeting/GetBookedlMeetingNotes",
        method: "POST",
        data: str_in,
      });
      rows1
        .then((resnotes) => {
          this.setState({ data: resnotes.data.pagedata });
          this.setState({ rows: resnotes.data.pagedata });
          this.setState({ rlen: resnotes.data.totalcount });
          this.setState({ flen: resnotes.data.totalcount });
          this.setState({ countpage: resnotes.data.pagecount });
        })
        .catch((error) => {
          toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
        });
    } catch (error) {
      toast.error("An error occurred. Please try again.");
      this.setState({ IsLoading: false });
    }
  };

  requestSearch = (event) => {
    try {
      if (event.key === "Enter") {
        event.preventDefault(); // Prevent any default form submission behavior
        var searchedVal = document.getElementById("search").value;
        this.setState({ page: 1, rowsPerPage: 100, search: searchedVal }, () => {
          // The setState function can take a callback function that is called after the state has been updated.
          // You can call NotesGet here to ensure it runs after the state is updated.
          this.NotesGet();
        });
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
      this.setState({ IsLoading: false });
    }
  };

  DeleteClientName = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete a note.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    })
      .then((result) => {
        if (result.isConfirmed) {
          var data = {
            _id: id,
            LastUpdatedDate: new Date(),
            LastUpdatedBy: this.state.CUserID,
            Role: this.state.Role,
          };
          Axios({
            url:
              CommonConstants.MOL_APIURL +
              "/bookedmeeting/DeleteBookedNotesMeeting",
            method: "POST",
            data: data,
          })
            .then((res) => {
              //
              if (res) {
                if ((res.statusText = "Ok")) {
                  Swal.fire(
                    "Deleted!",
                    "Delete booked meeting Notes Meeting successfully.",
                    "success"
                  );
                  this.NotesGet();
                  // history.push({pathname:"/viewbookedmeeting", state:{data:this.state.meetingid,Pagename:""}});
                } else {
                }
              }
            })
            .catch((error) => {
              toast.error("An error occurred. Please try again.");
              this.setState({ IsLoading: false });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      })
      .catch((error) => {
        toast.error("An error occurred. Please try again.");
        this.setState({ IsLoading: false });
      });
  };

  chengeRowSelected = (event) => {
    try {
      this.setState({ rowsPerPage: event.target.value });
      this.setState({ page: 1 });
      var str_in = {
        ClientID: this.state.ClientID,
        MeetingID: this.state.meetingid,
        Page: this.state.page,
        RowsPerPage: this.state.rowsPerPage,
        Sort: true,
        Field: this.state.sortField,
        Sortby: this.state.sortedBy,
        Search: this.state.search,
        Type: "User",
        Role: this.state.Role,
      };
      const rows1 = Axios({
        url:
          CommonConstants.MOL_APIURL + "/bookedmeeting/GetBookedlMeetingNotes",
        method: "POST",
        data: str_in,
      });
      rows1
        .then((resnotes) => {
          this.setState({ data: resnotes.data.pagedata });
          this.setState({ rows: resnotes.data.pagedata });
          this.setState({ rlen: resnotes.data.totalcount });
          this.setState({ flen: resnotes.data.totalcount });
          this.setState({ countpage: resnotes.data.pagecount });
        })
        .catch((error) => {
          toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
        });
    } catch (error) {
      toast.error("An error occurred. Please try again.");
      this.setState({ IsLoading: false });
    }
  };

  async fromValidationNotes() {
    try {
      let formIsValid = true;
      let fileds = this.state.fields;
      let errors = {};
      var Title22 = document.getElementById("Title22").value.trim();
      var Notes22 = document.getElementById("Notes22").value.trim();
      var IsExist = await this.CheckExistBookedMeetingTitle(Title22);
      if (Title22 == "") {
        formIsValid = false;
        errors["Title22"] = "Please Enter Title";
      }
      if (Notes22 == "") {
        formIsValid = false;
        errors["Notes22"] = "Please Enter Notes";
      }
      if (IsExist == true) {
        formIsValid = false;
      }
      this.setState({ errors: errors });
      return formIsValid;
    } catch (error) {
      toast.error("An error occurred. Please try again.");
      this.setState({ IsLoading: false });
    }
  }

  handleChangeNotes(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
    if (fields.Title != "") {
      this.state.errors.Title = null;
      this.state.TitleAvailable = null;
      this.CheckExistBookedMeetingTitle(e.target.value);
    } else {
      this.state.TitleAvailable = null;
      this.state.errors.Title = null;
    }
  }

  async CheckExistBookedMeetingTitle(Title) {
    try {
      var str_in = {
        ClientID: this.state.ClientID,
        MeetingID:this.state.meetingid,
        Title:Title,
        Role: this.state.Role,
      };
      var resdata = await Axios({
        url: CommonConstants.MOL_APIURL + "/bookedmeeting/NotesMeetingExists",
        method: "POST",
        data: str_in,
      });
      if (resdata.data.StatusMessage == "SUCCESS") {
        if (resdata.data.Data.length > 0) {
          this.setState({ TitleAvailable: resdata.data.Data[0].Title });
          return true;
        } else {
          return false;
        }
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
      this.setState({ IsLoading: false });
    }
  }

  async savebtn(e) {
    try {
      // this.state.Errors.Name = null;
      // this.state.StatusAvailable = null;
      var Title1 = document.getElementById("Title22").value.trim();
      var Notes2 = document.getElementById("Notes22").value.trim();
      this.setState({ BtnDisabledSave: true });
      // e.preventDefault();
      // e.currentTarget.disabled = true;
      var Final_flag = await this.fromValidationNotes();
      if (Final_flag == true) {
        var data = {
          ClientID: this.state.ClientID,
          Title: Title1,
          Note: Notes2,
          IsDeleted: false,
          CreatedBy: this.state.CUserID,
          CreatedDate: new Date(),
          MeetingID: this.state.meetingid,
          Role: this.state.Role,
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL + "/bookedmeeting/AddBookedNotesMeeting",
          method: "POST",
          data: data,
        })
          .then((res) => {
            //
            if (res.data.StatusMessage == "SUCCESS") {
              toast.success(
                "Booked meeting notes add successfully.",
                "Add Booked meeting Status"
              );
              this.setState({ ShowNotePopup: false });
              this.setState({ BtnDisabledSave: false });
              this.NotesGet();
              //history.push({pathname:"/viewbookedmeeting", state:{data:this.state.meetingid,Pagename:""}});
            } else {
              toast.error(res.data.Message);
              this.setState({ BtnDisabledSave: false });
            }
          })
          .catch((error) => {
            toast.error("An error occurred. Please try again.");
            this.setState({ BtnDisabledSave: false });
            this.setState({ IsLoading: false });
          });
      } else {
        this.setState({ BtnDisabledSave: false });
        //document.getElementById("submitSave").disabled = false;
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
      this.setState({ IsLoading: false });
    }
  }

  editspage(id) {
    try {
      this.setState({ Notesid: id });
      var str_in = {
        id: id,
        Role: this.state.Role,
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL + "/bookedmeeting/BookedMeetingNotesById",
        method: "POST",
        data: str_in,
      })
        .then((res) => {
          //
          // if (res.data.StatusMessage == "SUCCESS") {
          this.setState({ OldTitleUpdate: res.data?.Title });
          document.getElementById("Title1").value = res.data?.Title;
          document.getElementById("Notes").value = res.data?.Note;
        })
        .catch((error) => {
          toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
        });
    } catch (error) {
      toast.error("An error occurred. Please try again.");
      this.setState({ IsLoading: false });
    }
  }

  async fromValidationNote() {
    try {
      let formIsValid = true;

      let fields = this.state.fields1;
      let errors1 = {};

      var Title1 = document.getElementById("Title1").value.trim();
      var Notes = document.getElementById("Notes").value.trim();
      var IsExist = await this.CheckExistBookedMeetingTitle1(Title1);
      if (Title1 == "") {
        formIsValid = false;
        errors1["Title1"] = "Please Enter Title";
      }
      if (Notes == "") {
        formIsValid = false;
        errors1["Notes"] = "Please Enter Notes";
      }
      if (IsExist == true) {
        formIsValid = false;
      }
      this.setState({ errors1: errors1 });

      return formIsValid;
    } catch (error) {
      toast.error("An error occurred. Please try again.");
      this.setState({ IsLoading: false });
    }
  }

  handleChangeNote(field, e) {
    let fields1 = this.state.fields1;
    fields1[field] = e.target.value;
    this.setState({ fields1 });
    if (fields1.Title != "") {
      this.state.errors1.Title = null;
      this.state.TitleAvailable = null;
      this.CheckExistBookedMeetingTitle1(e.target.value);
    } else {
      this.state.TitleAvailable = null;
      this.state.errors1.Title = null;
    }
  }

  async CheckExistBookedMeetingTitle1(Title) {
    var str_in = {
      ClientID: this.state.ClientID,
      MeetingID:this.state.meetingid,
      Title:Title,
      Role: this.state.Role,
    };
    var resdata = await Axios({
      url: CommonConstants.MOL_APIURL + "/bookedmeeting/NotesMeetingExists",
      method: "POST",
      data: str_in,
    });
    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.Data.length > 0) {
        if (this.state.OldTitleUpdate == Title) {
          return false;
        } else {
          this.setState({ TitleAvailable: resdata.data.Data[0].Title });
          return true;
        }
      } else {
        return false;
      }
    }
  }

  async saveupdatebtn(e, closeCallback) {
    try {
      var Title = document.getElementById("Title1").value.trim();
      var Notes = document.getElementById("Notes").value.trim();
      // e.preventDefault();
      // e.currentTarget.disabled = true;
      this.setState({ BtnDisabledUpdate: true });
      var FinalValidation = await this.fromValidationNote();
      if (FinalValidation == true) {
        var data = {
          _id: this.state.Notesid,
          ClientID: this.state.ClientID,
          MeetingID: this.state.meetingid,
          MeetingNoteID: this.state.Notesid,
          Title: Title,
          Note: Notes,
          IsDeleted: false,
          LastUpdatedBy: this.state.CUserID,
          LastUpdatedDate: new Date(),
          Role: this.state.Role,
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/bookedmeeting/BookedNotesMeetingUpdate",
          method: "POST",
          data: data,
        })
          .then((res) => {
            if (res.data.StatusMessage == "SUCCESS") {
              toast.success(
                "Booked meeting notes updated successfully.",
                "Add Booked meeting Status"
              );
              closeCallback();
              this.setState({ ShowNotePopupUpdate: false });
              this.setState({ BtnDisabledUpdate: false });
              this.NotesGet();
              //history.push({pathname:"/viewbookedmeeting", state:{data:this.state.meetingid,Pagename:""}});
            } else {
              toast.error(res.data.Message);
              this.setState({ BtnDisabledUpdate: false });
            }
          })
          .catch((error) => {
            toast.error("An error occurred. Please try again.");
            this.setState({ BtnDisabledUpdate: false });
            this.setState({ IsLoading: false });
          });
      } else {
        this.setState({ BtnDisabledUpdate: false });
        //document.getElementById("submitupdate").disabled = false;
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
      this.setState({ IsLoading: false });
    }
  }

  GetBookedmeeting() {
    try {
      var Details = GetUserDetails();
      var str_in = {
        ClientID: Details.ClientID,
        UserID: Details.ParentUserID,
        Role: Details.Role,
      };
      //
      const rows = Axios({
        url: CommonConstants.MOL_APIURL + "/bookedmeeting/FindMeetingAllStatus",
        method: "POST",
        data: str_in,
      });
      rows
        .then((result) => {
          //
          this.setState({
            meetingCustomField: result.data.meetingCustomField,
          });
        })
        .catch((error) => {
          //toast.error("An error occurred. Please try again.");

          this.setState({ IsLoading: false });
        });
    } catch (error) {
      //toast.error("An error occurred. Please try again.");
      this.setState({ IsLoading: false });
    }
  }

  //get sort field Data
  SortData(Field) {
    try {
      var SearchedVal = document.getElementById("search").value;
      this.setState({ IsLoading: true });
      var SerchBox;
      if (SearchedVal == "") {
        SerchBox = false;
        this.setState({ sflag: false });
        var SortField = Field;
        var SortBy;
        if (this.state.sortedBy == 1) {
          SortBy = -1;
          this.setState({ sortedBy: -1 });
        } else {
          SortBy = 1;
          this.setState({ sortedBy: 1 });
        }
        this.setState({ sortField: Field });
        var InputParameter = {
          ClientID: this.state.ClientID,
          MeetingID: this.state.meetingid,
          Page: this.state.page,
          RowsPerPage: this.state.rowsPerPage,
          Sort: true,
          Field: this.state.sortField,
          Sortby: SortBy,
          Search: this.state.search,
          Type: "User",
          Role: this.state.Role,
        };
        const AccountCategoriesList = Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/bookedmeeting/GetBookedlMeetingNotes",
          method: "POST",
          data: InputParameter,
        });
        AccountCategoriesList.then((resnotes) => {
          this.setState({ data: resnotes.data.pagedata });
          this.setState({ rows: resnotes.data.pagedata });
          this.setState({ rlen: resnotes.data.totalcount });
          this.setState({ flen: resnotes.data.totalcount });
          this.setState({ countpage: resnotes.data.pagecount });
          this.setState({ IsLoading: false });
        }).catch((error) => {
          toast.error("An error occurred. Please try again.");

          this.setState({ IsLoading: false });
        });
      } else {
        SerchBox = true;
        this.setState({ sflag: true });
        var SortField = Field;
        var SortBy;
        if (this.state.sortedBy == 1) {
          SortBy = -1;
          this.setState({ sortedBy: -1 });
        } else {
          SortBy = 1;
          this.setState({ sortedBy: 1 });
        }
        this.setState({ sortField: Field });
        var InputParameter = {
          ClientID: this.state.ClientID,
          MeetingID: this.state.meetingid,
          Page: this.state.page,
          RowsPerPage: this.state.rowsPerPage,
          Sort: true,
          Field: this.state.sortField,
          Sortby: SortBy,
          Search: this.state.search,
          Type: "User",
          Role: this.state.Role,
        };
        const AccountCategoriesList = Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/bookedmeeting/GetBookedlMeetingNotes",
          method: "POST",
          data: InputParameter,
        });
        AccountCategoriesList.then((resnotes) => {
          this.setState({ data: resnotes.data.pagedata });
          this.setState({ rows: resnotes.data.pagedata });
          this.setState({ rlen: resnotes.data.totalcount });
          this.setState({ flen: resnotes.data.totalcount });
          this.setState({ countpage: resnotes.data.pagecount });
          this.setState({ IsLoading: false });
        }).catch((error) => {
          toast.error("An error occurred. Please try again.");

          this.setState({ IsLoading: false });
        });
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
      this.setState({ IsLoading: false });
    }
  }

  //View booked meeting Status History
  // requestSearch1 = (event) => {
  //   if (event.key === "Enter") {
  //     event.preventDefault();
  //   var searchedVal = document.getElementById("search1").value;
  //   this.setState({ page1: 1 });
  //   this.setState({ rowsPerPage1: 10 });
  //   this.setState({ search1: searchedVal });

  //   var str_in = {
  //     ClientID: this.state.ClientID,
  //     EventID: this.state.meetingid,
  //     Page: this.state.page1,
  //     RowsPerPage: this.state.rowsPerPage1,
  //     Sort: true,
  //     Field: this.state.sortField1,
  //     Sortby: this.state.sortedBy1,
  //     Search: this.state.search1,
  //     Type: "User",
  //     Role:this.state.Role
  //   };
  //   const rows1 = Axios({
  //     url: CommonConstants.MOL_APIURL + "/bookedmeeting/GetBookedlMeetingStatusHistory",
  //     method: "POST",
  //     data: str_in,
  //   });
  //   rows1.then((reshistory) => {
  //     this.setState({ data1: reshistory.data.pagedata });
  //       this.setState({ rows1: reshistory.data.pagedata });
  //       this.setState({ rlen1: reshistory.data.totalcount });
  //       this.setState({ flen1: reshistory.data.totalcount });
  //       this.setState({ countpage1: reshistory.data.pagecount });
  //   });
  // }
  // };
  requestSearch1 = async (event) => {
    try {
      if (event.key === "Enter") {
        this.setState({ IsLoading: true });
        event.preventDefault();
        const searchedVal = document.getElementById("search1").value;
        // Combine setState calls
        this.setState(
          {
            page1: 1,
            rowsPerPage1: 100,
            search1: searchedVal,
          },
          () => {
            var str_in = {
              ClientID: this.state.ClientID,
              EventID: this.state.meetingid,
              Page: this.state.page1,
              RowsPerPage: this.state.rowsPerPage1,
              Sort: true,
              Field: this.state.sortField1,
              Sortby: this.state.sortedBy1,
              Search: this.state.search1,
              Type: "User",
              Role: this.state.Role,
            };
            const rows1 = Axios({
              url:
                CommonConstants.MOL_APIURL +
                "/bookedmeeting/GetBookedlMeetingStatusHistory",
              method: "POST",
              data: str_in,
            });
            rows1
              .then((reshistory) => {
                this.setState({ data1: reshistory.data.pagedata });
                this.setState({ rows1: reshistory.data.pagedata });
                this.setState({ rlen1: reshistory.data.totalcount });
                this.setState({ flen1: reshistory.data.totalcount });
                this.setState({ countpage1: reshistory.data.pagecount });
                this.setState({ IsLoading: false });
              })
              .catch((error) => {
                toast.error("An error occurred. Please try again.");

                this.setState({ IsLoading: false });
              });
          }
        );
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
      this.setState({ IsLoading: false });
    }
  };

  handleChangePage1 = (event, newPage) => {
    try {
      this.setState({ page1: newPage });
      var str_in = {
        ClientID: this.state.ClientID,
        EventID: this.state.meetingid,
        Page: this.state.page1,
        RowsPerPage: this.state.rowsPerPage1,
        Sort: true,
        Field: this.state.sortField1,
        Sortby: this.state.sortedBy1,
        Search: this.state.search1,
        Type: "User",
        Role: this.state.Role,
      };
      const rows1 = Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/bookedmeeting/GetBookedlMeetingStatusHistory",
        method: "POST",
        data: str_in,
      });
      rows1
        .then((reshistory) => {
          this.setState({ data1: reshistory.data.pagedata });
          this.setState({ rows1: reshistory.data.pagedata });
          this.setState({ rlen1: reshistory.data.totalcount });
          this.setState({ flen1: reshistory.data.totalcount });
          this.setState({ countpage1: reshistory.data.pagecount });
        })
        .catch((error) => {
          toast.error("An error occurred. Please try again.");

          this.setState({ IsLoading: false });
        });
    } catch (error) {
      toast.error("An error occurred. Please try again.");
      this.setState({ IsLoading: false });
    }
  };

  chengeRowSelected1 = (event) => {
    //
    try {
      this.setState({ rowsPerPage1: event.target.value });
      this.setState({ page1: 1 });
      var str_in = {
        ClientID: this.state.ClientID,
        EventID: this.state.meetingid,
        Page: this.state.page1,
        RowsPerPage: this.state.rowsPerPage1,
        Sort: true,
        Field: this.state.sortField1,
        Sortby: this.state.sortedBy1,
        Search: this.state.search1,
        Type: "User",
        Role: this.state.Role,
      };
      const rows1 = Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/bookedmeeting/GetBookedlMeetingStatusHistory",
        method: "POST",
        data: str_in,
      });
      rows1
        .then((reshistory) => {
          this.setState({ data1: reshistory.data.pagedata });
          this.setState({ rows1: reshistory.data.pagedata });
          this.setState({ rlen1: reshistory.data.totalcount });
          this.setState({ flen1: reshistory.data.totalcount });
          this.setState({ countpage1: reshistory.data.pagecount });
        })
        .catch((error) => {
          toast.error("An error occurred. Please try again.");

          this.setState({ IsLoading: false });
        });
    } catch (error) {
      toast.error("An error occurred. Please try again.");
      this.setState({ IsLoading: false });
    }
  };

  //get sort field Data
  SortData1(Field) {
    try {
      var SearchedVal = document.getElementById("search1").value;
      this.setState({ IsLoading: true });
      var SerchBox;
      if (SearchedVal == "") {
        SerchBox = false;
        this.setState({ sflag1: false });
        var SortField = Field;
        var SortBy;
        if (this.state.sortedBy1 == 1) {
          SortBy = -1;
          this.setState({ sortedBy1: -1 });
        } else {
          SortBy = 1;
          this.setState({ sortedBy1: 1 });
        }
        this.setState({ sortField1: Field });
        var InputParameter = {
          ClientID: this.state.ClientID,
          EventID: this.state.meetingid,
          Page: this.state.page1,
          RowsPerPage: this.state.rowsPerPage1,
          Sort: true,
          Field: this.state.sortField1,
          Sortby: SortBy,
          Search: this.state.search1,
          Type: "User",
          Role: this.state.Role,
        };
        const AccountCategoriesList = Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/bookedmeeting/GetBookedlMeetingStatusHistory",
          method: "POST",
          data: InputParameter,
        });
        AccountCategoriesList.then((reshistory) => {
          this.setState({ data1: reshistory.data.pagedata });
          this.setState({ rows1: reshistory.data.pagedata });
          this.setState({ rlen1: reshistory.data.totalcount });
          this.setState({ flen1: reshistory.data.totalcount });
          this.setState({ countpage1: reshistory.data.pagecount });
          this.setState({ IsLoading: false });
        }).catch((error) => {
          toast.error("An error occurred. Please try again.");

          this.setState({ IsLoading: false });
        });
      } else {
        SerchBox = true;
        this.setState({ sflag1: true });
        var SortField = Field;
        var SortBy;
        if (this.state.sortedBy1 == 1) {
          SortBy = -1;
          this.setState({ sortedBy1: -1 });
        } else {
          SortBy = 1;
          this.setState({ sortedBy1: 1 });
        }
        this.setState({ sortField1: Field });
        var InputParameter = {
          ClientID: this.state.ClientID,
          EventID: this.state.meetingid,
          Page: this.state.page1,
          RowsPerPage: this.state.rowsPerPage1,
          Sort: true,
          Field: this.state.sortField1,
          Sortby: SortBy,
          Search: this.state.search1,
          Type: "User",
          Role: this.state.Role,
        };
        const AccountCategoriesList = Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/bookedmeeting/GetBookedlMeetingStatusHistory",
          method: "POST",
          data: InputParameter,
        });
        AccountCategoriesList.then((reshistory) => {
          this.setState({ data1: reshistory.data.pagedata });
          this.setState({ rows1: reshistory.data.pagedata });
          this.setState({ rlen1: reshistory.data.totalcount });
          this.setState({ flen1: reshistory.data.totalcount });
          this.setState({ countpage1: reshistory.data.pagecount });
          this.setState({ IsLoading: false });
        }).catch((error) => {
          toast.error("An error occurred. Please try again.");

          this.setState({ IsLoading: false });
        });
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
      this.setState({ IsLoading: false });
    }
  }

  Handlevalidationfornotesedit() {
    let Errors1 = {};
    Errors1["Title1"] = "";
    Errors1["Notes"] = "";
    this.state.TitleAvailable = null;
    this.setState({ errors1: Errors1 });
  }

  Handlevalidationfornotesadd() {
    this.setState({ ShowNotePopup: false });
    let Errors = {};
    Errors["Title22"] = "";
    Errors["Notes22"] = "";
    this.state.TitleAvailable = null;
    this.setState({ errors: Errors });
  }

  editAccountHandle(id) {
    if (id !== undefined && id !== null && id !== "000000000000000000000000") {
      history.push({
        pathname: "/viewaccount",
        state: { data: id, Pagename: "BookedMeeting" },
      });
    }
  }

  editContactHandle(id, Email) {
    if (id !== undefined && id !== null && id !== "000000000000000000000000") {
      const url = `/viewcontacts?id=${id}&email=${encodeURIComponent(Email)}&pagename=BookedMeeting`;
      window.open(url, '_blank');
    }
  }

  render() {
    return (
      <>
        {this.state.IsLoading ? (
          <div id="hideloding" className="loding-display">
            <img src={loadingicon} />
          </div>
        ) : (
          <></>
        )}
        <div className="adminmain">
          <Sidebar className="" />
          <div>
            <div className="bodyhome">
              <div className="row py-3">
                <div className="col-md-4 col-sm-4 padd-0 d-flex align-items-center">
                  <h3 className=" float-left lg-headertitle addpotential_header mb-0">
                    Edit Meeting
                  </h3>
                </div>

                <div className="col-md-8 col-sm-8 mt-2 text-right">
                  {/* <a className="btn btngroup m-btn mr-1">
                    <i className="la la-exchange"></i>
                    <span className="Mobile_button">Convert</span>
                  </a> */}
                  {/* {this.state.allres.map((value) => ( */}
                    <a
                      className="btn btngroup m-btn mr-1"
                      onClick={() => {
                        this.editbtn(this.state.allres[0]?._id);
                      }}
                    >
                      <i className="la flaticon-edit-1 mr-1"></i> 
                      <span className="Mobile_button">Edit</span>
                    </a>
                  {/* ))} */}
                  <a
                    className="btn m-btn btngroup2"
                    onClick={this.backbtn}
                  >
                    <i className="la la-arrow-circle-left mr-1"></i>
                    <span className="Mobile_button">Back</span>
                  </a>
                </div>
              </div>
              <div className="bg-white px-3">
                <div className="row border-bottom pb-3">
                  <div className="col-md-4 col-sm-4 pt-4 padd-0 d-flex align-items-center">
                    <h3 className="xs-headertitle mb-0 float-left addpotential_header ">
                      Contact Info
                    </h3>
                  </div>
                </div>

                {this.state.allres.map((value) => (
                  <div className="row">
                    <div className="col-lg-6 viewboxsinput_group">
                      <div className="row max-cols  d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          First Name
                        </label>
                        <div className="col-lg-7 View_text">
                        <a
                          onClick={() => {
                            this.editContactHandle(value?.P?._id,value.Email);
                          }}
                        >
                          <u>{value?.P?.FirstName || value.FirstName}</u>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 viewboxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          
                          Last Name
                        </label>
                        <div className="col-lg-7 View_text">
                        <a
                          onClick={() => {
                            this.editContactHandle(value?.P?._id,value.Email);
                          }}
                        >
                          <u>{value?.P?.LastName || value.LastName}</u>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 viewboxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Title
                        </label>
                        <div className="col-lg-7 View_text">
                          <label>{value?.JobTitle}</label>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 viewboxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Company
                        </label>
                        <div className="col-lg-7 View_text">
                          <label>{value.Company}</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 viewboxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Email
                        </label>
                        <div className="col-lg-7 View_text">
                          <label>{value.Email}</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 viewboxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          LinkedIn URL
                        </label>
                        <div className="col-lg-7 View_text">
                          <label>{value.LinkedInUrl}</label>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 viewboxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Direct Phone
                        </label>
                        <div className="col-lg-7 View_text">
                          <label>{value.DirectPhone}</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 viewboxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Direct Phone Ext
                        </label>
                        <div className="col-lg-7 View_text">
                          <label>{value.DirectPhoneExt}</label>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 viewboxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Company Phone
                        </label>
                        <div className="col-lg-7 View_text">
                          <label>{value.CompanyPhone}</label>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 viewboxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Website
                        </label>
                        <div className="col-lg-7 View_text">
                          <label><a href={`http://${value?.Website}`} className="text-dark" target="_blank" rel="noopener noreferrer"><u>{value?.Website}</u></a></label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 viewboxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Account Name:
                        </label>
                        <div className="col-lg-7 View_text">
                        <a
                          onClick={() => {
                            this.editAccountHandle(value?.SA?._id);
                          }}
                        >
                          <u>
                            {" "}
                            {value?.SA?.AccountName == undefined
                              ? ""
                              : value?.SA?.AccountName}
                          </u>
                        </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 viewboxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Industry :
                        </label>
                        <div className="col-lg-7 View_text">
                          <label>{value?.Industry}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="bg-white px-3 mt-3">
                <div className="row border-bottom pb-3">
                  <div className="col-md-4 pt-4 col-sm-4 padd-0 d-flex align-items-center">
                    <h3 className="xs-headertitle mb-0 float-left addpotential_header ">
                      Meeting Info
                    </h3>
                  </div>
                </div>
                {this.state.allres.map((value) => (
                  <div className="row pt-2">
                    <div className="col-lg-6 viewboxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Meeting Status:
                        </label>

                        <div className="col-lg-7 View_text">
                          <label>{this.state?.StatusMeeting}</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 viewboxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Meeting Source:
                        </label>
                        <div className="col-lg-7 View_text">
                          <label>{this.state?.sourcemeeting}</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 viewboxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Meeting Set Date :
                        </label>
                        <div className="col-lg-7 View_text">
                          <label>{value?.EventCreatedDt}</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 viewboxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Meeting Set By
                        </label>
                        <div className="col-lg-7 View_text">
                          <label>{this.state?.meetingsetbyname}</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 viewboxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Meeting Date :
                        </label>
                        <div className="col-lg-7 View_text">
                          <label> {value?.EventStartDt}</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 viewboxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Meeting Time Zone
                        </label>
                        <div className="col-lg-7 View_text">
                          <div className="col-lg-7 View_text">
                            <label>{this.state?.timezone}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 viewboxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Owner
                        </label>
                        <div className="col-lg-7 View_text">
                          <label>{this.state?.meetingowner}</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 viewboxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Reminder Email Account
                        </label>
                        <div className="col-lg-7 View_text">
                          <label>{this.state?.Accountemail}</label>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 viewboxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Contract Value :
                        </label>
                        <div className="col-lg-7 View_text">
                          <label>{value?.ContractValue}</label>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 viewboxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          External Account ID :
                        </label>
                        <div className="col-lg-7 View_text">
                          <label>{value?.ExternalAccountID}</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 viewboxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          External Contact ID :
                        </label>
                        <div className="col-lg-7 View_text">
                          <label>{value?.ExternalContactID}</label>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 viewboxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          External Field 1 :
                        </label>
                        <div className="col-lg-7 View_text">
                          <label>{value?.ExternalField1}</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 viewboxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          External Field 2 :
                        </label>
                        <div className="col-lg-7 View_text">
                          <label>{value?.ExternalField2}</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 viewboxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          External Field 3 :
                        </label>
                        <div className="col-lg-7 View_text">
                          <label>{value?.ExternalField3}</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 viewboxsinput_group"></div>
                    <div className="col-lg-6 viewboxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Seniority :
                        </label>
                        <div className="col-lg-7 View_text">
                          <label>{this.state?.seniority}</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 viewboxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        {value.IsSendMeetingReminder === true ? (
                          <label className="col-lg-4 view-lable-meet potential_text">
                            Yes
                          </label>
                        ) : (
                          <label className="col-lg-4 view-lable-meet potential_text">
                            No
                          </label>
                        )}
                        <div className="col-lg-7 View_text">
                          <label>Send Reminder</label>
                        </div>
                      </div>
                    </div>
                   
                    <div className="col-lg-6 viewboxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                        Contact Source :
                        </label>
                        <div className="col-lg-7 View_text">
                          <label>{value.ContactSourcesData?.Name}</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 viewboxsinput_group"></div>

                    <div className="col-lg-6 viewboxsinput_group">
                      <div className="row max-cols d-flex align-items-center">
                        <label className="col-lg-4 view-lable-meet potential_text">
                          Call Recording
                        </label>
                        <div className="col-lg-7">
                          {value?.RecordingAudioPath ? (
                            <audio
                              controls
                              style={{ outline: "none" }}
                              type="audio/mp3"
                            >
                              <source
                                src={
                                  CommonConstants.CallRecordingMeetingURL +
                                  value?.RecordingAudioPath
                                }
                              />
                              Your browser does not support the audio element.
                            </audio>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {/* <div className="col-md-4 pt-4 col-sm-4 padd-0 d-flex align-items-center">
                <h3 className="xs-headertitle mb-0 float-left addpotential_header ">
                  Status Dates
                </h3>
              </div>
              {this.state.allres.map((value) => (
                <div className="row pt-2">
                  <div className="col-lg-6 viewboxsinput_group">
                    <div className="row max-cols  d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Opportunity Date
                      </label>
                      <div className="col-lg-7 View_text">
                        <label>
                        {
                              value?.EventStartDt!=null?(Moment(
                                new Date(value?.EventStartDt)
                              ).format("MM/DD/YYYY")):("")
                              }
                        </label>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-lg-6 viewboxsinput_group">
                    <div className="row max-cols  d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Closed Lost Date
                      </label>
                      <div className="col-lg-7 View_text">
                        <label>{
                              value?.EventStartDt!=null?(Moment(
                                new Date(value?.EventStartDt)
                              ).format("MM/DD/YYYY")):("")
                              }</label>
                      </div>
                    </div>
                  </div> */}
              {/* </div> */}
              {/* ))} */}
             {/* Dynamic date status */}
             {
                this.state.StatusDateDetails.length > 0 && (            
                <div className="bg-white px-3 mt-3">
                <div className="row border-bottom pb-3">
                  <div className="col-md-4 pt-4 col-sm-4 padd-0 d-flex align-items-center">
                    <h3 className="xs-headertitle mb-0 float-left addpotential_header ">
                    Status Dates
                    </h3>
                  </div>
                </div>
                <div className="row ">
                  {this.state.StatusDateDetails?.map((value, index) => {
                    return (
                      <div className="col-lg-6 boxsinput_group">
                        <div
                          className="row max-cols d-flex align-items-center"
                          key={index}
                        >
                           <div className="col-lg-4">
                          <label >
                            {value?.NewStatus}:
                          </label>
                          </div>
                          <div className="col-lg-7 font-weight-normal">
                          <label style={{ fontWeight: 500 }}>{moment( new Date(value?.ChangeDate).toDateString()
                                ).format("MM/DD/YYYY")}</label>
                          </div>
                       
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>)
              }
             {/* Dynamic date status */}
              {
                this.state.meetingCustomField.length>0 && (            
                <div className="bg-white px-3 mt-3">
                <div className="row border-bottom pb-3">
                  <div className="col-md-4 pt-4 col-sm-4 padd-0 d-flex align-items-center">
                    <h3 className="xs-headertitle mb-0 float-left addpotential_header ">
                      Custom Meeting Field
                    </h3>
                  </div>
                </div>
                <div className="row ">
                  {this.state.meetingCustomField?.map((value, index) => {
                    return (
                      <div className="col-lg-6 boxsinput_group">
                        <div
                          className="row max-cols d-flex align-items-center"
                          key={index}
                        >
                          <label className="col-lg-4">
                            {value?.FieldName}:
                          </label>
                          {this.state.meetingCustomfieldbyid?.map((val) => {
                            if (value?._id === val?.CustomFieldID) {
                              return (
                                <div className="col-lg-7">
                                  <label>{val?.FieldValue}</label>
                                </div>
                              );
                            }
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>)
              }


              {/* notes */}
              <div className="bg-white px-3 mt-3 pb-3">
                <div className="row border-bottom">
                  <div className="col">
                    <h3 className=" float-left py-4 mb-0 addpotential_header">
                      Notes
                    </h3>
                  </div>
                  <div className="col text-right pt-3">
                    <button
                      onClick={() => {
                        this.setState({ ShowNotePopup: true });
                      }}
                      className="btn btngroup m-btn "
                    >
                      <i class="la la-plus la-icon-font-size-13"></i>
                      <span className="Mobile_button">Add</span>
                    </button>
                    <Popup open={this.state.ShowNotePopup}>
                      <div>
                        <div className="modal-black"></div>
                        <div className="filterPopup largerPopup">
                          <div className="paddingboxTerms">
                            <div className="modal-header py-3 px-3">
                              <h4 className="mb-0">Note</h4>
                            </div>
                            <div className="modal-body p-5">
                              <div class="row mb-3">
                                <label class="col-lg-2 col-form-label text-left">
                                  Title
                                </label>
                                <div class="col-lg-10">
                                  <input
                                    class="form-control m-input"
                                    data-val="true"
                                    data-val-remote="Title already exist."
                                    data-val-remote-url="/Utility/IsPotentialMeetingNoteTitleExist"
                                    data-val-required="Please enter title"
                                    id="Title22"
                                    name="Title"
                                    placeholder="Enter title"
                                    type="text"
                                    onBlur={this.handleChangeNotes.bind(
                                      this,
                                      "Title22"
                                    )}
                                  />
                                  <span style={{ color: "red" }}>
                                    {this.state.errors["Title22"]}
                                  </span>
                                  {this.state.TitleAvailable && (
                                    <span style={{ color: "red" }}>
                                      Title already exist.
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div class="row">
                                <label class="col-lg-2 col-form-label text-left">
                                  Note
                                </label>
                                <div class="col-lg-10">
                                  <textarea
                                    class="form-control m-input minheight390"
                                    data-val="true"
                                    data-val-remote="Note already exist."
                                    data-val-required="Please enter note"
                                    id="Notes22"
                                    name="Notes"
                                    placeholder="Enter note"
                                    type="text"
                                    // onBlur={this.handleChangeNotes.bind(
                                    //   this,
                                    //   "Notes22"
                                    // )}
                                  >
                                    {" "}
                                  </textarea>
                                  <span style={{ color: "red" }}>
                                    {this.state.errors["Notes22"]}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="modal-content bordernone text-right">
                              <div class="row">
                                <div class="col-lg-12 pull-right">
                                  <a
                                    id="backtolist"
                                    class="btn btn-secondary mr-2"
                                    onClick={this.Handlevalidationfornotesadd}
                                  >
                                    cancel
                                  </a>
                                  <button
                                    id="submitSave"
                                    class="btn btn-primary btn-lightgreen mr-1"
                                    value="Save"
                                    onClick={this.savebtn}
                                    disabled={this.state.BtnDisabledSave}
                                  >
                                    <i class="la la-save"></i> Save
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Popup>
                  </div>
                </div>
                <div className="row padt-25">
                  <div className="col-sm-12 col-md-6">
                    <label className="textlabelte">
                      Show
                      <select
                        name="tbl_meeting_length"
                        aria-controls="tbl_meeting"
                        className="form-control form-control-sm"
                        onChange={this.chengeRowSelected}
                        value={this.state.rowsPerPage}
                      >
                        {CommonConstants.show_rows.map((value) => (
                          <option value={value}>{value}</option>
                        ))}
                        {/* <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option> */}
                      </select>
                      entries
                    </label>
                  </div>
                  <div className="col-sm-12 col-md-6 full-right">
                    <label className="textlabelte">
                      Search:{" "}
                      <input
                        type="search"
                        id="search"
                        onKeyPress={(event) => this.requestSearch(event)}
                        className="form-control form-control-sm ml-2"
                        placeholder=""
                        aria-controls="tbl_meeting"
                      />
                    </label>
                  </div>
                </div>
                <div className="table-bordered">
                  <TableContainer component={Paper}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell onClick={() => {
                                this.SortData("Title");
                                this.setState({  NoteSortedColumn : "Title"})
                              }}>
                            
                              Title
                              <span className="shorting">
                         <ArrowUpward className={this.state.sortedBy === 1 && this.state.NoteSortedColumn === "Title" ? "active" : null} />
                          <ArrowDownward  className={this.state.sortedBy === -1 && this.state.NoteSortedColumn === "Title"  ? "active" : null} />
                          </span>
                          </TableCell>
                          <TableCell
                              onClick={() => {
                                this.SortData("Note");
                                this.setState({  NoteSortedColumn : "Note"})
                              }}
                            >
                              Notes
                              <span className="shorting">
                         <ArrowUpward className={this.state.sortedBy === 1 && this.state.NoteSortedColumn === "Note" ? "active" : null} />
                          <ArrowDownward  className={this.state.sortedBy === -1 && this.state.NoteSortedColumn === "Note"  ? "active" : null} />
                          </span>
                          </TableCell>
                          <TableCell
                              onClick={() => {
                                this.SortData("CreatedDate");
                                this.setState({  NoteSortedColumn : "CreatedDate"})
                              }}
                            >
                              Created Date
                              <span className="shorting">
                         <ArrowUpward className={this.state.sortedBy === 1 && this.state.NoteSortedColumn === "CreatedDate" ? "active" : null} />
                          <ArrowDownward  className={this.state.sortedBy === -1 && this.state.NoteSortedColumn === "CreatedDate"  ? "active" : null} />
                          </span>
                          </TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.rows.length === 0 ? (
                          <p className="text-center">
                            No data available in table
                          </p>
                        ) : (
                          this.state.rows.map((row) => (
                            // row.MeetingID!=null?(
                            <TableRow>
                              <TableCell>{row.Title}</TableCell>
                              <TableCell style={{ whiteSpace: 'pre-line' }}>{row.Note}</TableCell>
                              <TableCell>
                                {moment(row.CreatedDate).format("MM/DD/YYYY")}
                              </TableCell>
                              <TableCell>
                                <a
                                  onClick={() => {
                                    this.editspage(row._id);
                                  }}
                                >
                                  <Popup
                                    trigger={
                                      <i class="la flaticon-edit-1 edit-icon"></i>
                                    }
                                    modal
                                    nested
                                  >
                                    {(close) => (
                                      <div>
                                        <div className="modal-black"></div>
                                        <div className="filterPopup largerPopup">
                                          <div className="paddingboxTerms">
                                            <div className="modal-header py-3 px-3">
                                              <h4 className="mb-0">Note</h4>
                                            </div>
                                            <div className="modal-body p-5">
                                              <div class="row mb-3">
                                                <label class="col-lg-2 col-form-label text-left">
                                                  Title
                                                </label>
                                                <div class="col-lg-10">
                                                  <input
                                                    class="form-control m-input"
                                                    data-val="true"
                                                    data-val-remote="Title already exist."
                                                    data-val-remote-url="/Utility/IsPotentialMeetingNoteTitleExist"
                                                    data-val-required="Please enter title"
                                                    id="Title1"
                                                    name="Title1"
                                                    placeholder="Enter title"
                                                    type="text"
                                                    onBlur={this.handleChangeNote.bind(
                                                      this,
                                                      "Title1"
                                                    )}
                                                    // value={
                                                    //   this.state.fields1["Title1"]
                                                    // }
                                                  />
                                                  <span
                                                    style={{ color: "red" }}
                                                  >
                                                    {
                                                      this.state.errors1[
                                                        "Title1"
                                                      ]
                                                    }
                                                  </span>
                                                  {this.state
                                                    .TitleAvailable && (
                                                    <span
                                                      style={{ color: "red" }}
                                                    >
                                                      Title already exist.
                                                    </span>
                                                  )}
                                                </div>
                                              </div>

                                              <div class="row">
                                                <label class="col-lg-2 col-form-label text-left">
                                                  Note
                                                </label>
                                                <div class="col-lg-10">
                                                  <textarea
                                                    class="form-control m-input minheight390"
                                                    data-val="true"
                                                    data-val-remote="Title already exist."
                                                    data-val-required="Please enter title"
                                                    id="Notes"
                                                    name="Notes"
                                                    placeholder="Enter note"
                                                    type="text"
                                                    // onChange={this.handleChangeNote.bind(
                                                    //   this,
                                                    //   "Notes"
                                                    // )}
                                                    // value={
                                                    //   this.state.fields1["Notes"]
                                                    // }
                                                  >
                                                    {" "}
                                                  </textarea>
                                                  <span
                                                    style={{ color: "red" }}
                                                  >
                                                    {
                                                      this.state.errors1[
                                                        "Notes"
                                                      ]
                                                    }
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="modal-content bordernone text-right">
                                              <div class="row">
                                                <div class="col-lg-12 pull-right">
                                                  <a
                                                    id="backtolist"
                                                    class="btn btn-secondary mr-2"
                                                    onClick={() => {
                                                      close();
                                                      this.Handlevalidationfornotesedit();
                                                    }}
                                                  >
                                                    cancel
                                                  </a>
                                                  <button
                                                    id="submitupdate"
                                                    class="btn btn-primary btn-lightgreen mr-1"
                                                    href="javascript:void(0);"
                                                    value="Save"
                                                    disabled={
                                                      this.state
                                                        .BtnDisabledUpdate
                                                    }
                                                    onClick={(e) => {
                                                      // Pass the close function as a callback
                                                      this.saveupdatebtn(
                                                        e,
                                                        () => {
                                                          close(); // Call the close function when the update is successful
                                                        }
                                                      );
                                                    }}
                                                  >
                                                    <i class="la la-save"></i>{" "}
                                                    Save
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </Popup>
                                </a>
                                <a
                                  // href="#"
                                  className="btn-eyesicon"
                                  onClick={() => {
                                    this.DeleteClientName(row._id);
                                  }}
                                >
                                  <i class="la flaticon-delete-1 delete-icon"></i>
                                </a>
                              </TableCell>
                            </TableRow>
                            // ):(<></>)
                          ))
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
                {this.state.sflag ? (
                  <div class="row">
                    <Pagination
                      component="div"
                      count={this.state.countpage}
                      onChange={this.handleChangePage}
                      showFirstButton
                      showLastButton
                    />
                    <div class="col dataTables_info">
                      <p>
                        Showing{" "}
                        {this.state.rlen == 0
                          ? 0
                          : (this.state.page - 1) * this.state.rowsPerPage +
                            1}{" "}
                        to{" "}
                        {this.state.page * this.state.rowsPerPage >
                        this.state.rlen
                          ? this.state.rlen
                          : this.state.page * this.state.rowsPerPage}{" "}
                        of {this.state.rlen} entries (filtered from{" "}
                        {this.state.flen} total entries)
                      </p>
                    </div>
                  </div>
                ) : (
                  <div class="row">
                    <div class="col dataTables_info">
                      <p>
                        Showing{" "}
                        {this.state.rlen == 0
                          ? 0
                          : (this.state.page - 1) * this.state.rowsPerPage +
                            1}{" "}
                        to{" "}
                        {this.state.page * this.state.rowsPerPage >
                        this.state.rlen
                          ? this.state.rlen
                          : this.state.page * this.state.rowsPerPage}{" "}
                        of {this.state.rlen} entries
                      </p>
                    </div>
                    <div class="col pageright">
                      <Pagination
                        component="div"
                        count={this.state.countpage}
                        onChange={this.handleChangePage}
                        showFirstButton
                        showLastButton
                      />
                    </div>
                  </div>
                )}
              </div>
              {/* status History*/}
              <div className="bg-white px-3 mt-3 pb-4">
                <div className="row border-bottom">
                  <div className="col d-flex align-items-center">
                    <h3 className="xs-headertitle mb-0 float-left addpotential_header  py-4 mb-0">
                      Meeting Status History
                    </h3>
                  </div>
                  <div className="col text-right"></div>
                </div>
                <div className="row padt-25">
                  <div className="col-sm-12 col-md-6">
                    <label className="textlabelte">
                      Show
                      <select
                        name="tbl_meeting_length"
                        aria-controls="tbl_meeting"
                        className="form-control form-control-sm"
                        onChange={this.chengeRowSelected1}
                        value={this.state.rowsPerPage1}
                      >
                        {CommonConstants.show_rows.map((value) => (
                          <option value={value}>{value}</option>
                        ))}
                      </select>
                      entries
                    </label>
                  </div>
                  <div className="col-sm-12 col-md-6 full-right">
                    <label className="textlabelte">
                      Search:{" "}
                      <input
                        type="search1"
                        id="search1"
                        onKeyPress={(event) => this.requestSearch1(event)}
                        className="form-control form-control-sm ml-2"
                        placeholder=""
                        aria-controls="tbl_meeting"
                      />
                    </label>
                  </div>
                </div>
                <div className="table-bordered">
                  <TableContainer component={Paper}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                              onClick={() => {
                                this.SortData1("OldStatus");
                                this.setState({  HistoryStatusSortedColumn : "OldStatus"})
                              }}
                            >
                              Old Status
                              <span className="shorting">
                         <ArrowUpward className={this.state.sortedBy1 === 1 && this.state.HistoryStatusSortedColumn === "OldStatus" ? "active" : null} />
                          <ArrowDownward  className={this.state.sortedBy1 === -1 && this.state.HistoryStatusSortedColumn === "OldStatus"  ? "active" : null} />
                          </span>
                          </TableCell>
                          <TableCell
                              onClick={() => {
                                this.SortData1("NewStatus");
                                this.setState({  HistoryStatusSortedColumn : "NewStatus"})
                              }}
                            >
                              New Status
                              <span className="shorting">
                         <ArrowUpward className={this.state.sortedBy1 === 1 && this.state.HistoryStatusSortedColumn === "NewStatus" ? "active" : null} />
                          <ArrowDownward  className={this.state.sortedBy1 === -1 && this.state.HistoryStatusSortedColumn === "NewStatus"  ? "active" : null} />
                          </span>
                          </TableCell>
                          <TableCell
                              onClick={() => {
                                this.SortData1("ChangeDate");
                                this.setState({  HistoryStatusSortedColumn : "ChangeDate"})
                              }}
                            >
                              Change Date
                              <span className="shorting">
                         <ArrowUpward className={this.state.sortedBy1 === 1 && this.state.HistoryStatusSortedColumn === "ChangeDate" ? "active" : null} />
                          <ArrowDownward  className={this.state.sortedBy1 === -1 && this.state.HistoryStatusSortedColumn === "ChangeDate"  ? "active" : null} />
                          </span>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.rows1?.length === 0 ? (
                          <p className="text-center">
                            No data available in table
                          </p>
                        ) : (
                          this.state.rows1 &&
                          this.state.rows1?.map((row) => (
                            <TableRow>
                              <TableCell>
                                {row?.OldStatus || ""}
                              </TableCell>
                              <TableCell>
                              {row?.NewStatus || ""}
                              </TableCell>
                              <TableCell>
                                {moment(row?.ChangeDate).format("MM/DD/YYYY")}
                              </TableCell>
                            </TableRow>
                          ))
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
                {this.state.sflag1 ? (
                  <div class="row">
                    <Pagination
                      component="div"
                      count={this.state.countpage1}
                      onChange={this.handleChangePage1}
                      showFirstButton
                      showLastButton
                    />
                    <div class="col dataTables_info">
                      <p>
                        Showing{" "}
                        {this.state.rlen1 == 0
                          ? 0
                          : (this.state.page1 - 1) * this.state.rowsPerPage1 +
                            1}{" "}
                        to{" "}
                        {this.state.page1 * this.state.rowsPerPage1 >
                        this.state.rlen1
                          ? this.state.rlen1
                          : this.state.page1 * this.state.rowsPerPage1}{" "}
                        of {this.state.rlen1} entries (filtered from{" "}
                        {this.state.flen1} total entries)
                      </p>
                    </div>
                  </div>
                ) : (
                  <div class="row">
                    <div class="col dataTables_info">
                      <p>
                        Showing{" "}
                        {this.state.rlen1 == 0
                          ? 0
                          : (this.state.page1 - 1) * this.state.rowsPerPage1 +
                            1}{" "}
                        to{" "}
                        {this.state.page1 * this.state.rowsPerPage1 >
                        this.state.rlen1
                          ? this.state.rlen1
                          : this.state.page1 * this.state.rowsPerPage1}{" "}
                        of {this.state.rlen1} entries
                      </p>
                    </div>
                    <div class="col pageright">
                      <Pagination
                        component="div"
                        count={this.state.countpage1}
                        onChange={this.handleChangePage1}
                        showFirstButton
                        showLastButton
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedViewBookedMeetingPage = connect(
  mapState,
  actionCreators
)(ViewBookedMeetingPage);
export { connectedViewBookedMeetingPage as ViewBookedMeetingPage };
