import React from "react";
import Axios from "axios";

import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";

import { CommonConstants } from "../../../_constants/common.constants";
import { arrayMoveImmutable } from "array-move";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { history } from "../../../_helpers";
import { toast } from "react-toastify";
import { GetUserDetails } from "../../../_helpers/Utility";
import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import loadingicon from "../../../images/loading.gif";
import Swal from "sweetalert2";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

// Define a single item
const SortableItem = SortableElement(({ value }) => (
  <li className="boxcorder">{value}</li>
));

// Define the list
const SortableList = SortableContainer(({ newItems }) => (
  <ul>
    {newItems.map((item, index) => (
      <SortableItem key={`item-${index}`} index={index} value={item.DispositionsStatus} />
    ))}
  </ul>
));

class AddEditDialingSets extends React.Component {
  constructor(props) {
    super(props);
       const urlParams = window.location.pathname;
      var NavigationParams = urlParams === "/defaultphonesettings" ? "Default" : ""
    this.state = {
      NavigationParams,
      editItems: [],
      newItems: [],
      items: [],
      page: {},
      isChecked: true,
      ClientID: null,
      UserID: null,
      BtnDisabled:false,
      Role:null,
      Errors:{},
      StatusAvailable: null,
      Fields: {},
      Errors: {},
      isEditMode: false,  // New state variable for edit mode
      dialingsetsId: null, // Store the ID if editing
      checkedStatus: [], // New state to track selected checkboxes
      isChecked: true,
    };
    
  }

  async componentDidMount() {
    const Details = GetUserDetails();
    if (Details) {
      this.setState({
        ClientID: Details.ClientID,
        UserID: Details.ParentUserID,
        Role: Details.Role,
      });
    }

    const { dialingsetsIdForEdit, isEditMode } = this.props;
    if (isEditMode && dialingsetsIdForEdit) {
      this.setState({ isEditMode: true, dialingsetsId: dialingsetsIdForEdit });
      await this.EditDialingSets(dialingsetsIdForEdit, Details.Role);
    }
    await this.DispositionStatusColumnGet(Details.ClientID, Details.ParentUserID, Details.Role);
    this.HandleChangeBlur = this.HandleChangeBlur.bind(this);
  }

  async EditDialingSets(id, Role) {
    const requestData = { id, Role, PageType: this.state.NavigationParams };
    try {
      const response = await Axios.post(CommonConstants.MOL_APIURL + "/dialingsets/DialingSetsGetByID", requestData);
      if (response.data.StatusMessage === "SUCCESS") {
        const fetchedItems = response.data.Data?.StatusOrderDetails || [];
        const checkedStatus = fetchedItems.map((item) => item._id);
        this.setState({ newItems: fetchedItems, OldName: response.data.Data?.Name || "", checkedStatus });
        document.getElementById("DialingSet").value = response.data.Data?.Name;
        document.getElementById("hideloding").style.display = "none";
      } else {
        toast.error(response.data.Message);
      }
    } catch (error) {
      console.error("Error in EditDialingSets:", error);
      // toast.error("Failed to fetch dialing set details.");
    }
  }

  async DispositionStatusColumnGet(CID, UID, Role) {
    const requestData = { Type: "User", ClientID: CID, UserID: UID, Role, PageType: this.state.NavigationParams };
    try {
      const result = await Axios.post(CommonConstants.MOL_APIURL + "/dispositionstatus/DispositionsStatusDetaisGet", requestData);
      if (result.data.StatusMessage === "SUCCESS") {
        this.setState({ items: result.data.PageData, editItems: result.data.PageData, isLoading: false });
        document.getElementById("hideloding").style.display = "none";
      } else {
        this.setState({ items: [], isLoading: false });
        document.getElementById("hideloding").style.display = "none";
        toast.error(result.data.Message);
      }
    } catch (error) {
      console.error("Error in DispositionStatusColumnGet:", error);
      // toast.error("Failed to fetch disposition status data.");
    }
  }

  OnSortEnd = ({ oldIndex, newIndex }) => {
    this.setState({ newItems: arrayMoveImmutable(this.state.newItems, oldIndex, newIndex) });
  };


  // on sort end
  OnSortEnd = ({ oldIndex, newIndex }) => {
    this.setState({
      newItems: arrayMoveImmutable(this.state.newItems, oldIndex, newIndex),
    });
  };
  // form validation
  async FromValidations() {
    let FormIsValid = true;
    let Fields = this.state.Fields;
    let Errors = {};
    var DialingSet = document.getElementById("DialingSet").value.trim();
    var IsExist = await this.DialingSetCheckExist(DialingSet);
    if (DialingSet == "") {
      FormIsValid = false;
      Errors["DialingSet"] = "Please enter dialing set";
    }

    if (IsExist == "ExistStatus") {
      FormIsValid = false;
      Errors["DialingSet"] = "Dialing Sets already exist.";
    }
    if (IsExist == "BlankData" && DialingSet == "") {
      FormIsValid = false;
      Errors["DialingSet"] = "Please enter dialing sets";
    }
    if(this.state.newItems.length === 0){
      toast.error("Please select at least one status from the status column.");
      FormIsValid = false;
    }

    this.setState({ Errors: Errors });
    return FormIsValid;
  }
    // form validation
    HandleChangeBlur = async () => {
      let Errors = { ...this.state?.Errors };
      var DialingSet = document.getElementById("DialingSet").value.trim();
      var IsExist = await this.DialingSetCheckExist(DialingSet);
    
      if (IsExist === "ExistStatus") {
        Errors["DialingSet"] = "Dialing Set already exist.";
      }
    
      if (IsExist === "BlankData" && DialingSet === "") {
        Errors["DialingSet"] = "Please enter dialing set";
      }
    
      if (IsExist === "AllDone") {
        Errors["DialingSet"] = null;
      }
    
      this.setState({ Errors });
    };
      // find check exits meeting source
      async DialingSetCheckExist(Name) {
        if (Name != "") {
          var DialingSet = {
            ClientID: this.state.ClientID,
            UserID:this.state.UserID,
            Name: Name,
            Role: this.state.Role,
            PageType:this.state.NavigationParams
          };
          var ResponseData = await Axios({
            url: CommonConstants.MOL_APIURL + "/dialingsets/DialingSetsExists",
            method: "POST",
            data: DialingSet,
          });
    
          if (ResponseData.data.StatusMessage == "SUCCESS") {
            if (ResponseData.data.Data.length > 0) {
              if (this.state.OldName == Name) {
                return "AllDone";
              } else {
                this.setState({ StatusAvailable: ResponseData.data.Data[0].Name });
                return "ExistStatus";
              }
            } else {
              return "AllDone";
            }
          }
        } else {
          return "BlankData";
        }
      }
  // Save order data
  async SaveOrder() {
    // document.getElementById("hideloding").style.display = "block";
    // this.setState({ BtnDisabled: true });
    var Final_flag = await this.FromValidations();
    if(Final_flag == true){
      this.setState({ BtnDisabled: true }, () => {
        // Assign new OrderBy values to `newItems` based on their current position
        const reorderedItems = this.state.newItems.map((item, index) => ({
          ...item,
          OrderBy: index + 1, // Assign order starting from 1
        }));
    
        // Update the state with reordered items and save data
        this.setState(
          {
            newItems: reorderedItems,
            editItems: this.state.editItems.map((editItem) => {
              const newItem = reorderedItems.find((item) => item._id === editItem._id);
              return newItem ? { ...editItem, OrderBy: newItem.OrderBy } : editItem;
            }),
          },
          () => this.SaveData()
        );
      });
    }else{
      document.getElementById("hideloding").style.display = "none";
      this.setState({ IsButtonDisabled: false });
    }

  }
  

  SaveData() {
   console.log(this.state.newItems)
    Swal.fire({
      title: "Are you sure?",
      text: "you want to save dialing sets.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        var DialingSet = document.getElementById("DialingSet").value.trim();
        document.getElementById("hideloding").style.display = "block";
        if(this.state.isEditMode){
          const EditDialingSetsParams = {
            ID:this.state.dialingsetsId,
            Name:DialingSet,
            StatusOrderDetails: this.state.newItems,
            LastUpdatedBy:this.state.UserID,
            PageType:this.state.NavigationParams,
            CreatedDt:new Date(),
            Role:this.state.Role,
            ClientID:this.state.ClientID,
            UserID:this.state.UserID,
          };
          console.log(EditDialingSetsParams,"str_in")
          const rows1 = Axios({
            url: CommonConstants.MOL_APIURL + "/dialingsets/DialingSetsUpdate",
            method: "POST",
            data: EditDialingSetsParams,
          });
          rows1.then((result) => {
            document.getElementById("hideloding").style.display = "none";
            if (result.data.StatusMessage === "SUCCESS") {
              toast.success(
                "Dialing Sets updated successfully."
              );
                this.props.onUpdate(true)
            } else {
              toast.error(result.data.Message);
              this.setState({ BtnDisabled: false });
            }
          });
        }else{
          const AddDialingSetsParams = {
            Name:DialingSet,
            StatusOrderDetails: this.state.newItems,
            LastUpdatedBy:this.state.UserID,
            ClientID:this.state.ClientID,
            UserID:this.state.UserID,
            PageType:this.state.NavigationParams,
            CreatedBy:this.state.UserID,
            CreatedDt:new Date(),
            Role:this.state.Role
          };
          console.log(AddDialingSetsParams,"str_in")
          const rows1 = Axios({
            url: CommonConstants.MOL_APIURL + "/dialingsets/DialingSetsAdd",
            method: "POST",
            data: AddDialingSetsParams,
          });
          rows1.then((result) => {
            document.getElementById("hideloding").style.display = "none";
            if (result.data.StatusMessage === "SUCCESS") {
              toast.success(
                "Dialing Sets added successfully."
              );
                this.props.onUpdate(true)
            } else {
              toast.error(result.data.Message);
              this.setState({ BtnDisabled: false });
            }
          });
        }
   
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
        this.setState({ BtnDisabled: false });
      }
    });
  }
  
  StatusUpdateList(e, value) {
    const isChecked = e.target.checked;
  
    this.setState((prevState) => {
      // Create a copy of the newItems array
      let newItemsWithChange = [...prevState.newItems];
  
      if (isChecked) {
        // Check if the item does not exist in newItems, then add it
        const itemExists = newItemsWithChange.some((item) => item._id === value._id);
  
        if (!itemExists) {
          newItemsWithChange.push(value);
        }
      } else {
        // Remove the item if unchecked
        newItemsWithChange = newItemsWithChange.filter((item) => item._id !== value._id);
      }
  
      // Update the state with the modified arrays
      return {
        newItems: newItemsWithChange,
        editItems: prevState.editItems.map((updateItems) => {
          if (value._id === updateItems._id) {
            return { ...updateItems, IsInclude: isChecked };
          }
          return updateItems;
        }),
      };
    });

    const { checkedStatus } = this.state;
    const { checked } = e.target;

    // Update checked status based on user interaction
    if (checked) {
      this.setState({
        checkedStatus: [...checkedStatus, value._id],
      });
    } else {
      this.setState({
        checkedStatus: checkedStatus.filter((status) => status !== value._id),
      });
    }
  }
  

  // back button
  BackBtn() {
    this.props.onUpdate(true)
  }

  render() {
    const { isEditMode, Errors, BtnDisabled, isLoading, newItems } = this.state;
    return (
      <>
   <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div>

        <div className="adminmain"> 
          {/* <Sidebar className="" />  */}
          <div className="">
            <div class="paddcols bluechecked py-0">
            <div class="mb-3">
            <div className="row borderbtnm mx-0">
                <div className="col-md-6  text-right ">
                  <h4 className="headertitlepad xs-headertitle float-left">
                    
                    {this.state.isEditMode ? "Edit Dialing Sets" : "Add Dialing Sets"}
                  </h4>
                </div>
      
              </div>
              <div className="row py-2 mx-0 border-bottom">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Dialing Sets</label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter field name"
                        id="DialingSet"
                        onBlur={this.HandleChangeBlur}
                        name="DialingSet"
                        placeholder="Enter dialing set"
                        type="text"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.Errors["DialingSet"]}
                      </span>
                  
                    </div>
                  </div>
                </div>


              </div>
              <div className="row mx-0">
              <div class="col-md-12 pull-left pt-3 text-right pt-4">
                  <button
                  disabled={this.state.BtnDisabled}
                    id="submit"
                    onClick={() => {
                      this.SaveOrder();
                    }}
                    class="btn btn-primary btn-lightgreen mr-2"
                  >
                    <i class="la la-save"></i> Save
                  </button>
                  <a
                    id="backtolist"
                    href="javascript:void(0);"
                    class="btn btn-secondary"
                    onClick={() => {this.BackBtn()}}
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>

            </div>


              <div className="row borderbtnm mx-0 border-top border-bottom">
                <div className="col-md-6 text-right border-right">
                  <h4 className="headertitlepad xs-headertitle float-left">
                  All Status
                  </h4>
                </div>
                <div class="col-md-6 text-right ">
                  <h4 className="headertitlepad xs-headertitle float-left">
                  Status Column Ordering
                  </h4>
                </div>
              </div>

              <div className="row pt-4 mx-0">
                <div class="col-lg-6 boxsinput_group">

                  <div class="m-section">
                    <h3 class="m-section__heading">Status Column</h3>
                    <ul class="ddlist">
                    {this.state.items.map((value, Index) => (
              <li key={`item-${Index}`}>
                <div className="col-lg-6 row">
                  <label>
                    <input
                      type="checkbox"
                      id={Index}
                      checked={this.state.checkedStatus.includes(value._id)}
                      onChange={(e) => this.StatusUpdateList(e, value)}
                    />
                    {value.DispositionsStatus}
                  </label>
                </div>
              </li>
            ))}
                    </ul>
                  </div>
                </div>

                <div class="col-lg-6 boxsinput_group">
                <SortableList newItems={newItems} onSortEnd={this.OnSortEnd} />
                </div>
              </div>
            </div>

          </div>
        </div>
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedAddEditDialingSets = connect(
  mapState,
  actionCreators
)(AddEditDialingSets);
export { connectedAddEditDialingSets as AddEditDialingSets };
