import React,  { useEffect }from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import LastdaysTabing from '../../../_components/user/Kpis/LastdaysTabing';

import SubjectTable from '../variable/SubjectTable'; 
import GreetingTable from '../variable/GreetingTable'; 
import OpenerTable from '../variable/OpenerTable'; 
import FUOpenerTable from '../variable/FUOpenerTable'; 
import CTATable from '../variable/CTATable'; 
import CloserTable from '../variable/CloserTable'; 
import OptOutTable from '../variable/OptOutTable'; 
import ProblemTable from '../variable/ProblemTable'; 
import ValuePropTable from '../variable/ValuePropTable'; 
import StoryTable from '../variable/StoryTable'; 
import SenderTitleTable from '../variable/SenderTitleTable'; 
import { FormControl, MenuItem } from '@material-ui/core';

import { CSVReader, CSVDownloader } from "react-papaparse";
import ImageUploading from 'react-images-uploading';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));


export default function SimpleTabs({daterange,updateFromChild}) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(()=>{
    document.title = `Variable Sets | SalesHive`;  
  },[daterange]);

  return (
    <div className="tabdes">   
      <div className='px-2'> 
        <AppBar className='mb-3 col-tabs' position="static">
          <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
            <Tab label="Subject" {...a11yProps(0)}>Overviewasdasd</Tab>
            <Tab label="Greeting" {...a11yProps(1)} /> 
            <Tab label="Opener" {...a11yProps(2)} /> 
            <Tab label="FUOpener" {...a11yProps(3)} /> 
            <Tab label="CTA" {...a11yProps(4)} /> 
            <Tab label="Closer" {...a11yProps(5)} /> 
            <Tab label="OptOut" {...a11yProps(6)} /> 
            <Tab label="Problem" {...a11yProps(7)} /> 
            <Tab label="ValueProp" {...a11yProps(8)} /> 
            <Tab label="Story" {...a11yProps(9)} /> 
            <Tab label="Sender Title" {...a11yProps(10)} /> 
          </Tabs>
        </AppBar>
        <TabPanel value={value} className="tagpaddnone bg-white" index={0}>
          <div className="pt-0 pb-0"> 
            <div className="row mx-0">
              <div className="col"> 
              <SubjectTable FeatureName={"Subject"} FeatureFieldID={"7"} dater={daterange} udapate={updateFromChild}/> 

              </div>
            </div>



          </div>
        </TabPanel>
        
        <TabPanel value={value} className="tagpaddnone bg-white" index={1}>  
        <div className="pt-0 pb-0"> 
          <div className="row mx-0">
            <div className="col"> 
            <SubjectTable FeatureName={"Greeting"} FeatureFieldID={"3"} dater={daterange} udapate={updateFromChild}/>

            </div>
          </div>
        </div>
        </TabPanel>


        <TabPanel value={value} className="tagpaddnone bg-white" index={2}>  
          <div className="pt-0 pb-0"> 
            <div className="row">
              <div className="col"> 
              <SubjectTable FeatureName={"Opener"} FeatureFieldID={"5"} dater={daterange} udapate={updateFromChild}/> 
 
              </div>
            </div>
          </div>
        </TabPanel>

        <TabPanel value={value} className="tagpaddnone bg-white" index={3}>  
          <div className="pt-0 pb-0">  
            <div className="row mx-0">
              <div className="col"> 
              <SubjectTable FeatureName={"FUOpener"} FeatureFieldID={"6"} dater={daterange} udapate={updateFromChild}/>

              </div>
            </div>



          </div>
        </TabPanel>
        
        <TabPanel value={value} className="tagpaddnone bg-white" index={4}>  
        <div className="pt-0 pb-0">  
            <div className="row mx-0">
              <div className="col"> 
              <SubjectTable FeatureName={"CTA"} FeatureFieldID={"1"} dater={daterange} udapate={updateFromChild}/>

              </div>
            </div>
          </div>
        </TabPanel>

        <TabPanel value={value} className="tagpaddnone bg-white" index={5}>  
        <div className="pt-0 pb-0">  
            <div className="row mx-0">
              <div className="col"> 
              <SubjectTable FeatureName={"Closer"} FeatureFieldID={"2"} dater={daterange} udapate={updateFromChild}/>

              </div>
            </div>
          </div>
        </TabPanel>

        <TabPanel value={value} className="tagpaddnone bg-white" index={6}>  
        <div className="pt-0 pb-0"> 


            <div className="row">
              <div className="col"> 
              <SubjectTable FeatureName={"OptOut"} FeatureFieldID={"8"} dater={daterange} udapate={updateFromChild}/>

              </div>
            </div>
          </div>
        </TabPanel>

        <TabPanel value={value} className="tagpaddnone bg-white" index={7}>  
        <div className="pt-0 pb-0"> 
            <div className="row mx-0">
              <div className="col"> 
              <SubjectTable FeatureName={"Problem"} FeatureFieldID={"9"} dater={daterange} udapate={updateFromChild}/>

              </div>
            </div>



          </div>
        </TabPanel>

        <TabPanel value={value} className="tagpaddnone bg-white" index={8}>  
        <div className="pt-0 pb-0"> 
            <div className="row mx-0">
              <div className="col"> 
              <SubjectTable
                    FeatureName={"ValueProp"}
                    FeatureFieldID={"10"}
                    dater={daterange}
                  />
              </div>
            </div>

 

          </div>
        </TabPanel>

        <TabPanel value={value} className="tagpaddnone bg-white" index={9}>  
        <div className="pt-0 pb-0">  
            <div className="row mx-0">
              <div className="col"> 
              <SubjectTable FeatureName={"Story"} FeatureFieldID={"11"} dater={daterange} udapate={updateFromChild}/>

                </div> 
            </div>

          </div>
        </TabPanel>
        <TabPanel value={value} className="tagpaddnone bg-white" index={10}>  
        <div className="pt-0 pb-0"> 
            <div className="row mx-0">
              <div className="col"> 
              <SubjectTable
                    FeatureName={"Sender Title"}
                    FeatureFieldID={"4"}
                    dater={daterange}
                    udapate={updateFromChild}
                  />
              </div>
            </div>
          </div>
        </TabPanel>
       
      </div>
 

    </div>
  );
}