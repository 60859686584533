import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import Axios from "axios";
const moment = require("moment");
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import Popup from "reactjs-popup";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/froala_style.css";
import "froala-editor/js/plugins.pkgd.min.js";
import Froala from "froala-editor";
import FroalaEditor from "react-froala-wysiwyg";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from '@material-ui/core/Button';
import LinearProgress from '@mui/material/LinearProgress'; // Material-UI progress bar

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";

import { CommonConstants, skipScriptVariable, scriptVariable } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import loadingicon from "../../../images/loading.gif";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import StyleHeader from "../../../_components/user/StickyHeader/StickyHeader.js";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import MicIcon from "@material-ui/icons/Mic";
import StopIcon from "@material-ui/icons/Stop";
import PauseIcon from "@material-ui/icons/Pause";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";

const useStyles = makeStyles((theme) => ({
    dropzone: {
        border: '2px dashed #cccccc',
        borderRadius: '5px',
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer',
        minHeight: '100px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        '&:hover': {
            borderColor: '#888888',
        },
    },
    fileInfo: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        marginTop: '10px', // Add some space between dropzone text and file info
    },
    closeIcon: {
        cursor: 'pointer',
        color: '#ff0000',
        marginLeft: '10px',
        padding: '0', // Remove padding for better alignment
        minWidth: 'auto', // Prevent button from stretching
    },
    aside: {
        marginTop: '20px',
    },
    root: {
        flexGrow: 1,
    },
}));

// A function to reorder the rows array when dragged
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export default function ScriptTable(props) {
    const classes = useStyles();
    const [Data, SetData] = React.useState([]);
    const [Flen, SetFlen] = React.useState(0);
    const [Sflag, SetSflag] = React.useState(false);
    const [Rlen, SetRlen] = React.useState(0);
    const [CountPage, SetCountPage] = React.useState(0);
    const [Rows, SetRows] = React.useState([]);
    const [Page, SetPage] = React.useState(1);
    const [RowsPerPage, SetRowsPerPage] = React.useState(100);
    const [Search, SetSearch] = React.useState("");
    const [SortField, SetSortField] = React.useState("OrderBy");
    const [SortedBy, SetSortedBy] = React.useState(1);
    const [ClientID, SetClientID] = React.useState(0);
    const [UserID, SetUserID] = React.useState(0);
    const [Role, SetRole] = React.useState("");
    const [CUserID, SetCUserID] = React.useState(0);
    const [IsLoading, SetIsLoading] = React.useState(true);
    const [IsDataLoaded, setIsDataLoaded] = React.useState(false);
    const [IsScript, setIsScript] = useState(false);
    const [SignatureSC, SetSignatureSC] = useState({ data: "" });
    const [ButtonLabel, SetButtonLabel] = useState("Add");
    const [DataID, SetDataID] = useState("");
    const [OpenScript, SetOpenScript] = React.useState(false);
    const [ScriptName, SetScriptName] = React.useState("");
    const [NoteDetail, SetNoteDetail] = React.useState("");
    const [value, setValue] = React.useState(0);
    const [currentAudio, setCurrentAudio] = React.useState(null);

    const [isRecording, setIsRecording] = useState(false);
    const [isPaused, setIsPaused] = useState(false);
    const [duration, setDuration] = useState(0);
    const [audioUrl, setAudioUrl] = useState(null);
    const [playUrl, setPlayUrl] = useState(null);
    const mediaRecorderRef = useRef(null);
    const audioChunks = useRef([]);
    const intervalRef = useRef(null);
    const [file, setFile] = useState(null);
    const [progress, setProgress] = useState(0);

    const styles = {
        micButton: {
            fontSize: "30px",
            backgroundColor: "#FF3B30",
            borderRadius: "50%",
            width: "60px",
            height: "60px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "none",
            color: "#fff",
            cursor: "pointer",
        },
        controlButton: {
            margin: "10px",
            padding: "10px 20px",
            fontSize: "16px",
            backgroundColor: "#007AFF",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
        },
    };

    const onDragEnd = async (result) => {
        SetIsLoading(true); // Set the loader to true at the start
        if (!result.destination) {
            SetIsLoading(false); // If there's no destination, stop the loader
            return;
        }

        const newRows = reorder(Rows, result.source.index, result.destination.index);
        try {
            // Attempt to reorder data and call the script API
            await ReOrderData(newRows);
            await VoicemailGet(ClientID, UserID, Role);
        } catch (error) {
            console.error("Error during API calls:", error); // Log any errors encountered
        } finally {
            // Always set the loader to false, regardless of success or failure
            SetIsLoading(false);
        }
    };

    useEffect(() => {
        document.title = 'Dispositions Status | SalesHive';
        let Details = GetUserDetails();
        if (Details != null) {
            SetClientID(Details.ClientID);
            SetUserID(Details.ParentUserID);
            SetCUserID(Details.ChildUserID);
            SetRole(Details.Role);
        }
        VoicemailGet(Details.ClientID, Details.ParentUserID, Details.Role);
    }, [Search, Page, RowsPerPage]);

    useEffect(() => {
        if (isRecording && !isPaused) {
            intervalRef.current = setInterval(() => {
                setDuration((prev) => prev + 1);
            }, 1000);
        } else if (isPaused || !isRecording) {
            clearInterval(intervalRef.current);
        }
        return () => clearInterval(intervalRef.current);
    }, [isRecording, isPaused]);


    const { getRootProps, getInputProps } = useDropzone({
        accept: 'audio/mpeg',
        maxFiles: 1,
        onDrop: acceptedFiles => {
            setFile(acceptedFiles[0]);
            setProgress(0); // Reset progress when a new file is dropped
            simulateUpload();
        }
    });
    const simulateUpload = () => {
        const uploadInterval = setInterval(() => {
            setProgress(prev => {
                if (prev >= 100) {
                    clearInterval(uploadInterval);
                    setTimeout(() => {
                        setFile((prevFile) => prevFile);
                    }, 100);
                    return 100;
                }
                return prev + 10;
            });
        }, 200);
    };

    const handleDelete = () => {
        setFile(null); // Remove the file
        setProgress(0); // Reset the progress bar
    };

    const ReOrderData = async (Arr) => {
        var data = {
            VoicemailArray: Arr,
            ClientID: ClientID,
            UserID: UserID,
            Role: Role
        }
        const response = await Axios({
            url: CommonConstants.MOL_APIURL + "/voicemailrecordings/VoicemailReOrder",
            method: "POST",
            data: data,
        });
        if (response.data.StatusMessage === "SUCCESS") {
            toast.success("Reordered Successfully");
        } else {
            toast.error("Failed to reorder");
        }
    }

    //dispositions status get list
    const VoicemailGet = (CID, UID, URole) => {
        SetIsLoading(true)
        let ScriptData = {
            Page: Page,
            RowsPerPage: RowsPerPage,
            Sort: true,
            Field: SortField,
            SortBy: SortedBy,
            Search: Search,
            Type: "User",
            ClientID: CID,
            UserID: UID,
            Role: URole,
        };
        const ApiResponse = Axios({
            url: CommonConstants.MOL_APIURL + "/voicemailrecordings/VoicemailGet",
            method: "POST",
            data: ScriptData,
        });
        ApiResponse.then((result) => {
            SetData(result.data.PageData);
            SetRows(result.data.PageData);
            SetRlen(result.data.TotalCount);
            SetFlen(result.data.TotalCount);
            SetCountPage(result.data.PageCount);
            SetIsLoading(false);
            setIsDataLoaded(true);

        });
    };
    // Search details
    const RequestSearch = (event) => {
        if (event.key === "Enter") {
            let SearchedVal = document.getElementById("search").value;
            if (SearchedVal == Search) {
                SetSearch(SearchedVal);
                SetPage(1);
            } else {
                SetSearch(SearchedVal);
                SetPage(1);
                SetIsLoading(true);
            }
        }
    };
    // Delete function
    const DeleteBtn = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "you want to delete a voicemail recording.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#34bfa3",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, cancel!",
            reverseButtons: true,
        }).then((result) => {
            if (result.isConfirmed) {
                let ContactTagData = {
                    _id: id,
                    LastUpdatedBy: CUserID,
                    LastUpdatedDate: new Date(),
                    Role: Role,
                    ClientID: ClientID,
                    UserID: UserID
                };
                Axios({
                    url: CommonConstants.MOL_APIURL + "/voicemailrecordings/VoicemailDelete",
                    method: "POST",
                    data: ContactTagData,
                }).then((res) => {

                    if (res) {
                        if ((res.StatusMessage = "SUCCESS")) {
                            Swal.fire(
                                "Deleted!",
                                "Voicemail recording deleted successfully.",
                                "success"
                            );
                            SetIsLoading(true);
                            VoicemailGet(ClientID, UserID, Role);
                            SetPage(1)
                            SetSearch("")
                            document.getElementById("search").value = ""
                        } else {
                        }
                    }
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire("Cancelled", "Your data is safe :)", "error");
            }
        });
    };

    // change page
    const HandleChangePage = (event, NewPage) => {
        if (NewPage == Page) {
            SetPage(NewPage);
        } else {
            SetPage(NewPage);
            SetIsLoading(true);
        }
    };

    // change display rows
    const ChangeRowSelected = (Event) => {
        SetRowsPerPage(Number(Event.target.value));
        SetPage(1);
        SetIsLoading(true);
    };

    // download button
    const DownloadBtn = async (id) => {
        
        SetIsLoading(true)
        SetDataID(id);
        var Data = {
            ID: id,
            Role: Role
        };
        var requestAddScript = await Axios({
            url: CommonConstants.MOL_APIURL + "/voicemailrecordings/VoicemailGetByID",
            method: "POST",
            data: Data,
        });
        if (requestAddScript.data?.StatusMessage === "SUCCESS") {
            const fileName = requestAddScript.data?.VoicemailData?.FileName;
            const downloadUrl = CommonConstants.VoicemailRecordingsURL + fileName;

            // Fetch the file as a blob
            const response = await fetch(downloadUrl);
            const blob = await response.blob();

            // Create a temporary URL for the blob
            const blobUrl = window.URL.createObjectURL(blob);

            // Create a link element and trigger the download
            const link = document.createElement('a');
            link.href = blobUrl;
            link.setAttribute('download', fileName); // Force download
            document.body.appendChild(link);
            link.click();

            // Clean up the URL and remove the link
            window.URL.revokeObjectURL(blobUrl);
            document.body.removeChild(link);
            SetIsLoading(false)
        } else {
            toast.error("Failed to download");
            SetIsLoading(false);
        }
    };
    // add button
    const AddBtn = (id) => {
        setIsScript(true)
        SetButtonLabel("Add");
    };


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const AddVoicemailClose = async () => {
        
        if (audioUrl === null && file === null) {
            setIsScript(false);
        } else if (audioUrl) {
            setAudioUrl(null);
            document.getElementById('AddVoicemailName').value = "";
            setIsScript(false)
        } else {
            if (file !== null) {
                setFile(null);
                document.getElementById('AddVoicemailName1').value = "";
                setIsScript(false)
            }
        }
    }
    const VoicemailPopupSubmit = async () => {
        
        var VoicemailNameRecord;
        var VoicemailNameUpload;
        if (value === 0) {
            VoicemailNameRecord = document.getElementById('AddVoicemailName').value || "";
            VoicemailNameUpload = "";
        } else {
            VoicemailNameRecord = "";
            VoicemailNameUpload = document.getElementById('AddVoicemailName1').value || "";
        }

        const audioBlob = audioChunks.current.length > 0; // True if audio has been recorded
        const fileUploaded = !!file; // True if a file is uploaded
        const reader = new FileReader();
        let Base64Audio;
        const getBase64 = (file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();

                reader.readAsDataURL(file);

                reader.onloadend = () => {
                    resolve(reader.result);  // Resolve the Base64 string once file is read
                };

                reader.onerror = (error) => {
                    reject(error);  // Reject in case of an error
                };
            });
        };
        if (fileUploaded) {
            Base64Audio = await getBase64(file);
        }
        if (VoicemailNameRecord == "" && VoicemailNameUpload == "" && !audioBlob && !fileUploaded) {
            toast.error(
                <div className="toastsize">
                    Add Voicemail
                    <br />
                    Voicemail and audio is empty, fill it!
                </div>
            );
        } else if (VoicemailNameRecord == "" && VoicemailNameUpload == "") {
            toast.error(
                <div className="toastsize">
                    Add Voicemail
                    <br />
                    Voicemail Name is empty, fill it!
                </div>
            );
        } else if (!audioBlob && !fileUploaded) {
            toast.error(
                <div className="toastsize">
                    Add Voicemail
                    <br />
                    Voicemail audio is empty, record or upload it!
                </div>
            );
        } else {
            
            var Data = {
                VoicemailNameRecord: VoicemailNameRecord,
                VoicemailNameUpload: VoicemailNameUpload,
                Role: Role,
                ClientID: ClientID,
                UserID: UserID,
                Audio: audioBlob ? audioUrl : null, // Blob for recorded audio
                File: file ? Base64Audio : null // Use the uploaded file if exists
            };
            var requestAddScript = await Axios({
                url: CommonConstants.MOL_APIURL + "/voicemailrecordings/VoicemailRecordingAdd",
                method: "POST",
                data: Data,
            });
            if (requestAddScript.data?.StatusMessage === "EXISTS") {
                toast.error(
                    <div className="toastsize">
                        Add Voicemail Recording
                        <br />
                        Voicemail Recording Name is already added, change it!
                    </div>
                );
            } else if (requestAddScript.data?.StatusMessage === "ERROR") {
                toast.error(
                    <div className="toastsize">
                        Add Voicemail Recording
                        <br />
                        {requestAddScript.data?.Message}
                    </div>);
            } else if (requestAddScript.data?.StatusMessage === "SUCCESS") {
                if (fileUploaded) {
                    setFile(null);
                    document.getElementById('AddVoicemailName1').value = "";
                } else {
                    setAudioUrl(null);
                    document.getElementById('AddVoicemailName').value = "";
                }
                setIsScript(false)
                toast.success(<p>Add Voicemail Recording<br />You have added voicemail recording.</p>);
                VoicemailGet(ClientID, UserID, Role)
            }
        }
    }

    const handleStartRecording = async () => {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const mediaRecorder = new MediaRecorder(stream);

        mediaRecorder.ondataavailable = (event) => {
            if (event.data.size > 0) {
                audioChunks.current.push(event.data);
            }
        };

        mediaRecorder.onstop = () => {
            const audioBlob = new Blob(audioChunks.current, { type: "audio/wav" });
            const reader = new FileReader();
            reader.onloadend = () => {
                
                const audioUrl = URL.createObjectURL(audioBlob);
                setPlayUrl(audioUrl);
                const base64Audio = reader.result.split(',')[1]; // Extract base64 string
                console.log(base64Audio); // You can use this base64Audio string as needed
                setAudioUrl(base64Audio); // Or set it in state if necessary
            };
            reader.readAsDataURL(audioBlob);
            //audioChunks.current = []; // Reset audio chunks for next recording
        };

        mediaRecorderRef.current = mediaRecorder;
        mediaRecorder.start();
        setIsRecording(true);
        setDuration(0); // Reset the timer when starting a new recording
    };

    const handleStopRecording = () => {
        mediaRecorderRef.current.stop();
        setIsRecording(false);
        setIsPaused(false);
    };

    const handlePauseRecording = () => {
        if (isPaused) {
            mediaRecorderRef.current.resume();
        } else {
            mediaRecorderRef.current.pause();
        }
        setIsPaused(!isPaused);
    };

    const formatDuration = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${String(minutes).padStart(2, "0")}:${String(remainingSeconds).padStart(2, "0")}`;
    };

    const handlePlay = (audio) => {
        if (currentAudio && currentAudio !== audio) {
            currentAudio.pause();
            currentAudio.currentTime = 0; // Optional: reset to the beginning
        }
        setCurrentAudio(audio);
    };

    return (
        <div>
            {IsLoading ? (
                <div id="hideloding" className="loding-display">
                    <img src={loadingicon} />
                </div>
            ) : (
                <></>
            )}
            <div className="row align-items-center border-bottom mx-0">
                <div className="col">
                    <h4 className="headertitlebd xs-headertitle float-left ">Voicemail Recording</h4>
                </div>
                <div className="col">
                    <div className="listing-li float-right">
                        <ul class='my-0'>
                            <li>
                                <a
                                    onClick={() => {
                                        AddBtn();
                                    }}
                                    // onClick={this.AddBtn}
                                    className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                                >
                                    <i className="la la-icon-font-size-13 la-plus"></i>
                                    <span>Add</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class=''>
                <div className="row">
                    <div className="col padd-0"></div>
                </div>
                <div className="row pt-4 mx-0">
                    <div className="col-sm-12 col-md-6">
                        <label className="textlabelte">
                            Show
                            <select
                                name="tbl_meeting_length"
                                onChange={ChangeRowSelected}
                                aria-controls="tbl_meeting"
                                className="form-control form-control-sm"
                                value={RowsPerPage}
                            >
                                {CommonConstants.show_rows.map((value) => (
                                    <option value={value} key={value}>{value}</option>
                                ))}
                            </select>
                            entries
                        </label>
                    </div>
                    <div className="col-sm-12 col-md-6 full-right">
                        <label className="textlabelte">
                            Search:{" "}
                            <input
                                type="search"
                                id="search"
                                onKeyPress={RequestSearch}
                                className="form-control form-control-sm ml-2"
                                placeholder=""
                                aria-controls="tbl_meeting"
                            />
                        </label>
                    </div>
                </div>
                <div class='px-3'>
                    <div className="table-bordered">
                        <TableContainer component={Paper}>
                            <StyleHeader isDataLoaded={IsDataLoaded} />
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="Rows" direction="vertical">
                                    {(provided) => (
                                        <Table className="table-ref" {...provided.droppableProps} ref={provided.innerRef} stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell></TableCell>
                                                    <TableCell>Name</TableCell>
                                                    <TableCell>Created Data</TableCell>
                                                    <TableCell>Playback</TableCell>
                                                    <TableCell>Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>

                                                {Rows?.length === 0 ? (
                                                    <p className="text-center">No data available in table</p>
                                                ) : (
                                                    Rows?.map((row, index) => (
                                                        <Draggable key={row._id} draggableId={row._id} index={index}>
                                                            {(provided) => (
                                                                <>
                                                                    <TableRow key={row?._id}
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}>
                                                                        <TableCell align="center">
                                                                            <i class="fa fa-bars cursor-pointer"></i>

                                                                        </TableCell>
                                                                        <TableCell>{row.Name}</TableCell>
                                                                        <TableCell>{row.CreatedDate}</TableCell>
                                                                        <TableCell>                    <TableCell>
                                                                            {
                                                                                row.FileName && <audio controls style={{ outline: 'none', width: '300px' }} onPlay={(e) => handlePlay(e.target)} type='audio/mp3'>
                                                                                    <source src={`${CommonConstants.VoicemailRecordingsURL}${row.FileName}`} />
                                                                                </audio>
                                                                            }

                                                                        </TableCell></TableCell>
                                                                        <TableCell>
                                                                            <a
                                                                                onClick={() => {
                                                                                    DownloadBtn(row._id);
                                                                                }}
                                                                                className="btn-eyesicon"
                                                                                title="Download"
                                                                            >
                                                                                <i className="la la-icon-font-size-13 la-download"></i>
                                                                            </a>
                                                                            <a
                                                                                onClick={() => {
                                                                                    DeleteBtn(row._id);
                                                                                }}
                                                                                className="btn-eyesicon"
                                                                                title="Delete"
                                                                            >
                                                                                <i className="la flaticon-delete-1 delete-icon"></i>
                                                                            </a>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </>
                                                            )}
                                                        </Draggable>
                                                    )))}
                                            </TableBody>

                                            <div className="row"></div>
                                        </Table>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </TableContainer>
                    </div>
                </div>

                <div className="row mx-0 pb-2">
                    <div className="col dataTables_info">
                        <p>
                            Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
                            {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
                            entries
                        </p>
                    </div>
                    <div className="col pageright">
                        <Pagination
                            component="div"
                            count={CountPage}
                            page={Page}
                            onChange={HandleChangePage}
                            showFirstButton
                            showLastButton
                        />
                    </div>
                </div>
            </div>
            <Popup open={IsScript}>
                <div>
                    <div className="modal-black"></div>
                    <div className="filterPopup largerPopup1000">
                        <div className="paddingboxTerms">
                            <div className="modal-header py-4 px-3">
                                <div className="w-100 d-flex px-3">
                                    <h5 className="mb-0">{ButtonLabel} Script</h5>
                                    <button
                                        className="close"
                                        onClick={AddVoicemailClose}
                                    >
                                        <span
                                            aria-hidden="true"
                                            class="la la-remove"
                                        ></span>
                                    </button>
                                </div>
                            </div>
                            <div className={classes.root}>
                                <AppBar position="static">
                                    <Tabs
                                        value={value}  // Controlled by the component state
                                        onChange={handleChange}  // Handle tab switching
                                        variant="scrollable"
                                        aria-label="simple tabs example"
                                        className="px-4 pt-2"
                                    >
                                        <Tab label="Record" {...a11yProps(0)} />
                                        <Tab label="Upload" {...a11yProps(1)} />
                                    </Tabs>
                                </AppBar>
                                <TabPanel value={value} index={0}>
                                    <div class="row mb-4">
                                        <label class="col-lg-2 col-form-label textright-vmb">
                                            Voicemail Name
                                        </label>
                                        <div class="col-lg-9 d-flex">
                                            <input
                                                class="form-control m-input"
                                                type="text"
                                                id="AddVoicemailName"
                                            />
                                        </div>
                                    </div>
                                    <div className="modal-body">
                                        <div>
                                            {!isRecording && !audioUrl && (
                                                <button onClick={handleStartRecording} className="btn btn-secondary" style={styles.iconButton}>
                                                    <MicIcon fontSize="large" style={{ color: "#FF3B30" }} />
                                                </button>
                                            )}

                                            {isRecording && (
                                                <div>
                                                    <p>Recording Duration: {formatDuration(duration)}</p>
                                                    <button onClick={handlePauseRecording} className="btn btn-secondary mr-2" style={styles.iconButton}>
                                                        {isPaused ? (
                                                            <PlayArrowIcon fontSize="large" style={{ color: "#007AFF" }} />
                                                        ) : (
                                                            <PauseIcon fontSize="large" style={{ color: "#007AFF" }} />
                                                        )}
                                                    </button>
                                                    <button onClick={handleStopRecording} className="btn btn-secondary" style={styles.iconButton}>
                                                        <StopIcon fontSize="large" style={{ color: "#FF3B30" }} />
                                                    </button>
                                                </div>
                                            )}

                                            {audioUrl && !isRecording && (
                                                <div>
                                                    <audio controls>
                                                        <source src={playUrl} type="audio/wav" />
                                                        Your browser does not support the audio element.
                                                    </audio>
                                                    <p>Total Duration: {formatDuration(duration)}</p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    <div className="row mb-4">
                                        <label className="col-lg-2 col-form-label textright-vmb">Voicemail Name</label>
                                        <div className="col-lg-9 d-flex">
                                            <input className="form-control m-input" type="text" id="AddVoicemailName1" />
                                        </div>
                                    </div>
                                    <div {...getRootProps({ className: classes.dropzone })}>
                                        <input {...getInputProps()} />
                                        {!file ? (
                                            <p>Drag 'n' drop an MP3 file here, or click to select one</p>
                                        ) : (
                                            <div className={classes.fileInfo}>
                                                <Typography variant="body1">{file.path}</Typography>
                                                <Button
                                                    onClick={handleDelete}
                                                    className={classes.closeIcon}
                                                    size="small"
                                                    variant="text"
                                                >
                                                    X {/* Custom close icon */}
                                                </Button>
                                            </div>
                                        )}
                                    </div>

                                    {progress > 0 && progress < 100 && (
                                        <aside className={classes.aside}>
                                            <h4>Upload Progress</h4>
                                            <LinearProgress variant="determinate" value={progress} />
                                        </aside>
                                    )}
                                </TabPanel>
                            </div>

                        </div>
                        <div class="modal-footer">
                            <button
                                type="button"
                                class="btn btn-secondary"
                                data-dismiss="modal"
                                onClick={AddVoicemailClose}
                            >
                                Cancel
                            </button>
                            <button
                                id="SubmitStepTemplate"
                                onClick={VoicemailPopupSubmit}
                                class="btn btn-primary btnColor"
                            >
                                <i class="la la-save mr-2"></i> Save{" "}
                            </button>
                        </div>
                    </div>
                </div>
            </Popup >
        </div >
    );
}
