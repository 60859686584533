import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Axios from "axios";
import Popup from "reactjs-popup";
const Fileupload = require("../../../_helpers/fileupload");

import DomainTable from '../../../_components/user/GlobalBlackList/DomainTable';
import ContactTable from '../../../_components/user/GlobalBlackList/ContactTable'; 
import PhoneNumberDNC from '../../../_components/user/GlobalBlackList/PhoneNumberDNC';  
import PhoneNumberBlacklist from '../../../_components/user/GlobalBlackList/PhoneNumberBlacklist';  
 
import { CSVReader, CSVDownloader } from "react-papaparse";
import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";
import { GetClientDetails } from "../../../_helpers/Utility";
import { toast } from "react-toastify";
import $ from "jquery";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

import loadingicon from "../../../images/loading.gif";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));


export default function SimpleTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [popupval, setpopup] = React.useState(false);
  const [ClientID, setClientID] = React.useState(0);
  const [UserID, setUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [CUserID, setPUserID] = React.useState(0);
  const [BtnDisabled, SetBtnDisabled] = React.useState(false);
  const [BtnDisabled1, SetBtnDisabled1] = React.useState(false);
  const [BtnDisabled2, SetBtnDisabled2] = React.useState(false);
  const [BtnDisabled3, SetBtnDisabled3] = React.useState(false);
  const [phonenumberBtn, SetphonenumberBtn] = React.useState(false);

  const [Fields, SetFields] = React.useState({});
  const [Errors, SetErrors] = React.useState({});
  const [DomainNameAvaiable, SetDomainNameAvaiable] = React.useState(null);
  const [Exportdata, SetExportData] = React.useState([]);
  const [ContactDomainExportData, SetContactDomainExport] = React.useState([]);
  const [RemovedExportData, SetRemovedExportData] = React.useState([]);
  const [WhiteListExportData, SetWhiteListExportData] = React.useState([]);

  const [Reset, SetReset] = React.useState(false);
  const [IsLoading, SetIsLoading] = React.useState(false);
  const [DropboxData, SetDropboxData] = React.useState([]);
  const [filename, setfilename] = React.useState("");
  const [csvData, setcsvData] = React.useState([]);
  const [files, setfiles] = React.useState([]);
  const [CName, SetCName] = React.useState("");
  const [UpdateKey, SetUpdateKey] = React.useState(0);
  // contact
  const [Total, SetTotal] = React.useState(0);
  const [TotalSucess, SetTotalSucess] = React.useState(0);
  const [TotalError, SetTotalError] = React.useState(0);
  const [TotalDuplicate, SetTotalDuplicate] = React.useState(0);
  const [TotalInvalid, SetTotalInvalid] = React.useState(0);

  // remove
  const [RemovedTotal, SetRemovedTotal] = React.useState(0);
  const [RemovedTotalSucess, SetRemovedTotalSucess] = React.useState(0);
  const [RemovedTotalError, SetRemovedTotalError] = React.useState(0);
  const [RemovedTotalDuplicate, SetRemovedTotalDuplicate] = React.useState(0);
  const [RemovedTotalUpdated, SetRemovedTotalUpdated] = React.useState(0);

  // whitelist
  const [TotalWhiteList, SetTotalWhiteList] = React.useState(0);
  const [TotalWhiteListSucess, SetTotalWhiteListSucess] = React.useState(0);
  const [TotalWhiteListError, SetTotalWhiteListError] = React.useState(0);
  const [TotalWhiteListDuplicate, SetTotalWhiteListDuplicate] =
    React.useState(0);

  const [DParentChild, SetDParentChild] = React.useState(false);
  const [CParentChild, SetCParentChild] = React.useState(false);
  const [RParentChild, SetRParentChild] = React.useState(false);
  const [WParentChild, SetWParentChild] = React.useState(false);
  const [PhoneNumberParentChild, SetPhoneNumberParentChild] = React.useState(false);
  const [Flag, SetFlag] = React.useState(false);
  // Domain tab
  const [IsExist, SetIsExist] = React.useState(false);
  const [ShowPopupDomainImport, SetShowPopupDomainImport] =
    React.useState(false);
  const [IsValid, SetIsValid] = React.useState(false);
  const [UserEmail, SetUserEmail] = React.useState("");
  const [Domain, SetDomain] = React.useState(null);
  const [CreatedDate, SetCreatedDate] = React.useState(null);

  // update parent to chaild
  const [DomainChaild, SetDomainChaild] = React.useState(false);
  const [ContactChaild, SetContactChaild] = React.useState(false);
  const [RemovedChaild, SetRemovedChaild] = React.useState(false);
  const [WhiteListChaild, SetWhiteListChaild] = React.useState(false);
  const [PhoneNumberChaild, SetPhoneNumberChaild] = React.useState(false);

  //Display states

  const [ImportBox, SetImportBox] = React.useState(false);
  const [ImportMapBox, SetImportMapBox] = React.useState(false);
  const [DeleteBox, SetDeleteBox] = React.useState(false);
  const [DeleteMapBox, SetDeleteMapBox] = React.useState(false);
  const [AddBox, SetAddBox] = React.useState(false);
  const [CountBox, SetCountBox] = React.useState(false);
  const [BtnPopDisabled, SetBtnPopDisabled] = React.useState(false);
  const [DisabledFields, SetDisabledFields] = React.useState(false);
  const [Contact, SetContact] = React.useState(null);
  const [Removed, SetRemoved] = React.useState(null);
  const [Whitelist, SetWhitelist] = React.useState(null);
  const [PhoneNumber, SetPhoneNumber] = React.useState(null);
  const [OldPhoneNumberData, SetOldPhoneNumberData] = React.useState("");
  const [OpenImportPhoneNumber, SetOpenImportPhoneNumber] = React.useState(false);
  const [ImportPhoneNumberMapBox, SetImportPhoneNumberMapBox] = React.useState(false);
  const [PhoneNumberCountBox, SetPhoneNumberCountBox] = React.useState(false);
  const [PhoneNumberImportCount, SetPhoneNumberImportCount] = React.useState({
    Total:0,
    Success:0,
    Duplicate:0,
    Error:0,
    Invalid:0
  });
  const [DisplayPhoneCount, SetDisplayPhoneCount] = React.useState(false)
  const [PhoneNumberList, SetPhoneNumberList] = React.useState(null)

  const [OpenBulkDeletePhone, SetOpenBulkDeletePhone] = React.useState(false)
  const [BulkPhoneNumberMapBox, SetBulkPhoneNumberMapBox] = React.useState(false);



  useEffect( () => {
    document.title = "Global Blacklist | SalesHive"
    let Details = GetUserDetails();

    if (Details != null) {
      setClientID(Details.ClientID);
      setUserID(Details.ParentUserID);
      SetUserEmail(Details.Username);
      setPUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }

    GetClientDetails(Details.ClientID).then((result) => {
      SetCName(result[0].Name);
    });
    if(props?.propdata?.location?.state !== undefined){
      if(props?.propdata?.location?.state?.page == "contact"){
        setValue(1)
      }else{
        setValue(0)
      }
    }
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const HandleOnDrop = (data, fileInfo) => {
    var filename = fileInfo.name;
    setfiles(fileInfo);

    // Get the file extension by finding the last dot in the filename
    var sep = filename.lastIndexOf(".");
    var extension = filename.slice(sep + 1);
    
    if (extension !== "csv") {
      
      SetReset(true);
      resetcsv();
    } else {
      var csvfile = filename.slice(0, sep) + new Date().getTime() + "." + extension;
      
      setfilename(csvfile);
      setcsvData(data);
      
      SetDropboxData(
        data[0].data?.map(el => el?.trim())?.filter(el => el !== "") // Filter out any empty strings
      );
    }
  };

  const HandleOnError = (err, file, inputElem, reason) => { };

  const HandleOnRemoveFile = (data) => {
    // setcsvData([])
  };
  
  const WhiteListImportBtn = () => {
    SetAddBox(false);
    SetImportBox(true);
    SetImportMapBox(false);
    SetDeleteBox(false);
    SetDeleteMapBox(false);
    SetShowPopupDomainImport(false);
    SetCountBox(false);
    setcsvData([]);
    resetcsv();
    SetDisabledFields(false)
  };

  const WhiteListExportCsvBtn = () => {
    toast.success(
      <div>
        Whitelist
        <br />
        Data exported successfully.
      </div>
    );
  };

  // for whitelist delete
  const WhiteListDeleteBtn = () => {
    SetAddBox(false);
    SetImportBox(false);
    SetImportMapBox(false);
    SetDeleteBox(true);
    SetDeleteMapBox(false);
    SetShowPopupDomainImport(false);
    SetCountBox(false);
  };

  const WhiteListShowBtn = () => {
    SetAddBox(true);
    SetImportBox(false);
    SetImportMapBox(false);
    SetDeleteBox(false);
    SetDeleteMapBox(false);
    SetShowPopupDomainImport(false);
    SetCountBox(false);
    SetBtnDisabled3(false);
    SetOpenBulkDeletePhone(false)
    SetBulkPhoneNumberMapBox(false)
    SetOpenImportPhoneNumber(false)
    SetImportPhoneNumberMapBox(false);
  };

  // for white list import csv
  const WhiteListImportMapCsv = () => {
    SetDisabledFields(false)
    SetCountBox(false);

    if (csvData.length != 0) {
      SetAddBox(false);
      SetImportBox(true);
      SetImportMapBox(true);
      SetDeleteBox(false);
      SetDeleteMapBox(false);
      SetShowPopupDomainImport(false);
      SetCountBox(false);
      DropboxData.map((dddd, index) => { });
      resetcsv();
    } else {
      toast.error(
        <div className="toastsize">
          Whitelist
          <br />
          Please select file
        </div>
      );
      SetAddBox(false);
      SetImportBox(true);
      SetImportMapBox(false);
      SetDeleteBox(false);
      SetDeleteMapBox(false);
      SetShowPopupDomainImport(false);
      SetCountBox(false);

      resetcsv();
    }
    hideCSVHeaderOptions();
  };

  // for whitelist csv
  const WhiteListCancelCsv = () => {
    resetcsv();
    SetAddBox(false);
    SetImportBox(false);
    SetImportMapBox(false);
    SetDeleteBox(false);
    SetDeleteMapBox(false);
    SetShowPopupDomainImport(false);
    SetCountBox(false);
    SetDisabledFields(false)

  };


  const UpdateFromChild = (value, page) => {
    if (value == true) {
      if (page === "Domain") {
        SetDParentChild(true);
        SetUpdateKey(UpdateKey + 1);
      } else if (page === "Contact") {
        SetCParentChild(true);
        SetUpdateKey(UpdateKey + 1);
      } else if (page === "Removed") {
        SetRParentChild(true);
        SetUpdateKey(UpdateKey + 1);
      } else {
        SetWParentChild(true);
        SetUpdateKey(UpdateKey + 1);
      }
    }
  };

   const addpage=()=> {
      history.push("/addglobalblacklist");
    }
    const addContactpage=()=> {
      history.push("/addglobalblacklistcontact");
    }

    //////// Phone Number

  const PhoneNumberAdd = async () => {
    SetphonenumberBtn(true);
    var IsValid = await PhoneNumberOnBlur();
    if (IsValid) {
      var Phonenumber = document.getElementById("phonenumber").value.trim();

      var PhonenumberAddData = {
        ClientID: ClientID,
        PhoneNumber: Phonenumber,
        UserID: UserID,
        CreatedBy: CUserID,
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/global_phonenumberblacklist/phonenumberadd",
        method: "POST",
        data: PhonenumberAddData,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          SetPhoneNumberChaild(true);
          toast.success(<div className="toastsize">Phone Number<br />Phone Number added successfully.</div>);
          document.getElementById("phonenumber").value = "";

          SetPhoneNumberChaild(false);
          SetPhoneNumberParentChild(true)
          PhoneNumberClose();
          SetUpdateKey(UpdateKey + 1);
        } else {
          toast.error("Internal server error");
          SetphonenumberBtn(false);
        }
      });
    } else {
      SetphonenumberBtn(false);
    }
  };

  const PhoneNumberClose = () => {
    SetErrors({});
    SetAddBox(false);
    SetImportBox(false);
    SetImportMapBox(false);
    SetDeleteBox(false);
    SetDeleteMapBox(false);
    SetShowPopupDomainImport(false);
    SetCountBox(false);
    SetphonenumberBtn(false);
  };
  const PhoneNumberOnBlur = async () => {
    let formIsValid = true;
    let errors = {};
    var PhoneNumber = document.getElementById("phonenumber").value.trim();
    let IsExist = await PhoneNumberCheckExist(PhoneNumber);

    if (PhoneNumber == "" && PhoneNumber.indexOf(" ") != "") {
      formIsValid = false;
      errors["PhoneNumberlist"] = "Please enter phone number";
    }else if(!/^[0-9]+$/.test(PhoneNumber?.trim())){
      formIsValid = false;
      errors["PhoneNumberlist"] = "Invalid phone number";
    }
    if (IsExist == true) {
      formIsValid = false;
    }
    SetErrors(errors);
    return formIsValid;
  };

  // cheack exists for contact email tab
  const PhoneNumberCheckExist = async (phonenumber) => {
    var str_in = {
      ClientID: ClientID,
      UserID: UserID,
      PhoneNumber: phonenumber,

    };
    let resdata = await Axios({
      url: CommonConstants.MOL_APIURL + "/global_phonenumberblacklist/phonenumberCheackExists",
      method: "POST",
      data: str_in,
    });
    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.PhoneNumberData.length > 0) {
        if (OldPhoneNumberData == phonenumber) {
          SetIsExist(false);
          return false;
        } else {
          toast.error("Phone number is already exists!");
          SetIsExist(true);
          SetIsLoading(false)
          return true;
        }
      } else {
        SetIsExist(false);
        SetIsLoading(false)
        return false;
      }
    }
  };

  const ExportBacklinks = (ClientID) => {
    SetIsLoading(true)
    var requestData = {
      ClientID: ClientID
   };

    Axios({
      url: CommonConstants.MOL_APIURL + "/global_phonenumberblacklist/phonenumberblacklistExport",
      method: "POST",
      data: requestData,
    }).then((Result) => {
      if (Result.data.StatusMessage = "SUCCESS") {
        var url = Result.data.PageData;
        const a = document.createElement('a');
        a.href = url;
        a.click();
        URL.revokeObjectURL(url);

       
        toast.success(<div className="toastsize">Phone number blacklist  <br />  Data exported successfully.</div>);
        SetIsLoading(false)
      }
      else {
        SetIsLoading(false)
      }

    }).catch(error => {
      console.error('Error fetching export Phone number blacklist', error);
      SetIsLoading(false)
    });
  }

  // Open Import phone number div
  const OpenImportPhoneNumberDiv = () => {
    SetOpenImportPhoneNumber(true);
    SetImportPhoneNumberMapBox(false);
    SetDropboxData([]);
    setcsvData([]);
    SetAddBox(false);
    SetOpenBulkDeletePhone(false);
    SetBulkPhoneNumberMapBox(false);
    SetPhoneNumberList(null);

  }

  // Upload CSV file in phone number 
  const UploadCSVInPhoneNumber = () => {
    SetDisabledFields(false)
    SetCountBox(false);

    if (csvData.length != 0) {
      SetImportPhoneNumberMapBox(true);
      SetPhoneNumberCountBox(false)
      SetAddBox(false);
      SetImportBox(true);
      SetImportMapBox(true);
      SetDeleteBox(false);
      SetDeleteMapBox(false);
      SetShowPopupDomainImport(false);
      SetCountBox(false);
      DropboxData.map((dddd, index) => { });
      hideCSVHeaderOptions();
      resetcsv();
    } else {
      toast.error(
        <div className="toastsize">
          Phone number
          <br />
          Please select file
        </div>
      );
      SetAddBox(false);
      SetImportPhoneNumberMapBox(false);
      SetDeleteBox(false);
      SetDeleteMapBox(false);
      SetShowPopupDomainImport(false);
      SetCountBox(false);

      resetcsv();
    }
  }

  //Save Csv data into DB
  const SavePhoneNumberCSVDetails = () => {
    SetDisabledFields(true)

    SetIsLoading(true);
    var PhoneNumberIndex = document.getElementById("PhoneNumber").value;
    if (PhoneNumberList != "not mapped") {
      var csvdata = csvData;
      Fileupload.getBase64(files, (result) => {
        var rs = result;
        var splirs = rs.split("base64,");
        var _base64 = splirs[1];

        var myresult = {
          Fields: "Coverimg",
          Path: "./Content/UploadedFiles/PhoneNumberBlacklist",
          File: _base64,
          ImageName: filename,
          FileData: csvdata,
          PhoneNumberIndex: PhoneNumberIndex,
          ClientID: ClientID,
          Role: Role,
          UserID: UserID,
          CreatedBy: UserID,
          CreatedDate: new Date(),
        };

        Axios({
          url:
            CommonConstants.MOL_APIURL +"/global_phonenumberblacklist/BulkImportPhoneNumber",
          method: "POST",
          data: myresult,
        }).then((res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            SetPhoneNumberImportCount({
              Total:res.data.Data?.Total,
              Success:res.data.Data?.Success,
              Duplicate:res.data.Data?.Duplicate,
              Error:res.data.Data?.Error,
              Invalid:res.data.Data?.Invalid,
            });
            SetPhoneNumberList(null);
            resetcsv();
            SetDisplayPhoneCount(true)
            // SetAddBox(false);
            // SetImportBox(true);
            // SetImportMapBox(true);
            // SetDeleteBox(false);
            // SetDeleteMapBox(false);
            // SetShowPopupDomainImport(false);
            // SetCountBox(true);
            // document.getElementById("WhiteCSVImport").disabled = true;
            // document.getElementById("Whitelist").disabled = true;
            // document.getElementById("Whitelist").disabled = true;
            SetUpdateKey(UpdateKey + 1);
            setcsvData([]);
            SetIsLoading(false);
            SetWhiteListChaild(true);
          } else {
            toast.error(res.data.message);
            SetIsLoading(false);
            SetDisabledFields(false)

          }
        });
      });
    } else {
      toast.error(
        <div className="toastsize">
          Whitelist <br /> Whitelist not mapped!
        </div>
      );
      SetIsLoading(false);
      SetDisabledFields(true)


    }
  }

  //Cancel to upload csv file in phone number
  const CancelToUploadPhoneNumberCSV = () => {
    resetcsv();
    SetOpenImportPhoneNumber(false)
    SetImportPhoneNumberMapBox(false);
    SetPhoneNumberCountBox(false)
    SetDisplayPhoneCount(false)
    SetPhoneNumberList(null);
    SetAddBox(false);
    SetImportBox(false);
    SetDeleteBox(false);
    SetDeleteMapBox(false);
    SetCountBox(false);
    SetDisabledFields(false)
  }

  //Open bulk delete model
  const OpenBulkDeleteModel = () => {
    SetOpenBulkDeletePhone(true)
    SetBulkPhoneNumberMapBox(false)
    SetOpenImportPhoneNumber(false)
    SetImportPhoneNumberMapBox(false);
    SetPhoneNumberList(null);
    resetcsv();
    setcsvData([]);
    SetAddBox(false)

  }

  // Mapping bulk delete phone number
  const MappingBulkDeletePhone = () => {
    if (csvData.length != 0) {
      SetBulkPhoneNumberMapBox(true)
      SetAddBox(false);
      SetImportBox(false);
      SetImportMapBox(false);
      SetDeleteBox(true);
      SetDeleteMapBox(true);
      SetShowPopupDomainImport(false);
      SetCountBox(false);
      hideCSVHeaderOptions()
      resetcsv();
    } else {
      resetcsv();
      setcsvData([]);
      toast.error(
        <div className="toastsize">
          Phone number
          <br />
          Please select file
        </div>
      );
      SetAddBox(false);
      SetImportBox(false);
      SetImportMapBox(false);
      SetDeleteBox(true);
      SetDeleteMapBox(false);
      SetShowPopupDomainImport(false);
      SetCountBox(false);

    }
    
  }

  // Bulk deleting phone number
  const BulkDeletingPhoneNumber = () => {
    var PhoneNumberIndex = document.getElementById("WhitelistName1").value;
    if (PhoneNumberIndex != "not mapped") {
      SetIsLoading(true);
      var csvdata = csvData;
      Fileupload.getBase64(files, (result) => {
        var rs = result;
        var splirs = rs.split("base64,");
        var _base64 = splirs[1];

        var myresult = {
          Fields: "Coverimg",
          Path: "./Content/UploadedFiles/PhoneNumberBlacklist",
          File: _base64,
          ImageName: filename,
          FileData: csvdata,
          PhoneNumberIndex: PhoneNumberIndex,
          Role: Role,
          UserID: UserID,
          ClientID: ClientID,
          CreatedBy: UserID,
          CreatedDate: new Date(),
        };

        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/global_phonenumberblacklist/BulkDeletePhoneNumber",
          method: "POST",
          data: myresult,
        }).then((res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            SetPhoneNumberList(null)
            CloseBulkDeleteModel()
            SetIsLoading(false);
            SetAddBox(false);
            SetImportBox(false);
            SetImportMapBox(false);
            SetDeleteBox(false);
            SetDeleteMapBox(false);
            SetShowPopupDomainImport(false);
            SetCountBox(false);
            toast.success(
              <div className="toastsize">
                Phone number <br /> Phone number deleted successfully.
              </div>
            );
            SetUpdateKey(UpdateKey + 1);

          } else {
            toast.error(res.data.Message);
            SetIsLoading(false);
          }
        });
      });
    } else {
      toast.error(
        <div className="toastsize">
          Phone number <br /> Phone  number not mapped!
        </div>
      );
      SetIsLoading(false);
    }
  }

  //Close bulk delete model
  const CloseBulkDeleteModel = () => {
    SetOpenBulkDeletePhone(false);
    SetBulkPhoneNumberMapBox(false)
    SetPhoneNumberList(null);
    setcsvData([]);

  }

  return (
    <div className="tabdes"> 
      <div> 
        <AppBar position="static">
          <Tabs value={value} onChange={handleChange} variant="scrollable" aria-label="simple tabs example"> 
            <Tab label="Domain" {...a11yProps(0)}>Overviewasdasd</Tab>
            <Tab label="Contact" {...a11yProps(1)} /> 
            <Tab label="Phone Number" {...a11yProps(2)} /> 
            <Tab label="DNC" {...a11yProps(3)} /> 
          </Tabs>
        </AppBar>
        <TabPanel value={value} className="tagpaddnone bg-white" index={0}>
            <div className="row mx-0 align-items-center border-bottom">
              <div className="col">
                  <h4 className="headertitle xs-headertitle pl-0 float-left">Domain</h4>   
              </div> 
              <div className="col">
                  <div className="listing-li float-right">
                      <ul className='my-0'>
                       <li>
                       <a
                        onClick={() => addpage()}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i className="la la-icon-font-size-13 la-plus"></i>
                        <span>Add</span>
                      </a>
                        </li> 
                      </ul>
                  </div>
              </div>
            </div>


           

            <div className="row p-3">
            <div className="col">
              <div className="table-bordered border-0 my-0">
                <DomainTable />
              </div>
            </div>
          </div> 
        </TabPanel>
        <TabPanel value={value} className="tagpaddnone bg-white" index={1}>
            <div className="row mx-0 align-items-center">
              <div className="col border-bottom">
                  <h4 className="headertitle xs-headertitle float-left pl-0">Contact</h4>   
              </div> 
              <div className="col border-bottom">
                  <div className="listing-li float-right padb-15">
                      <ul>
                       <li>
                       <a
                        onClick={() => addContactpage()}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i className="la la-icon-font-size-13 la-plus"></i>
                        <span>Add</span>
                      </a>
                        </li> 
                      </ul>
                  </div>
              </div>
            </div>



            <div className="row">
            <div className="col">
              <div className="table-bordered border-0 my-0">
                <ContactTable />
              </div>
            </div>
          </div> 
        </TabPanel>
        <TabPanel value={value} className="tagpaddnone bg-white" index={2}>
        <div className="row align-items-center mx-0 border-bottom">
            <div className="col">
              <h4 className="headertitle xs-headertitle float-left  pl-0">Phone Number</h4>
            </div>
            <div className="col">
              <div className="listing-li float-right padb-15 pr-1">
                <ul>
                  <li>
                    <a
                      onClick={() => {
                        WhiteListShowBtn();
                      }}
                      className="btn btngroup m-btn"
                    >
                      <i className="la la-icon-font-size-13 la-plus"></i>
                      <span>Add</span>
                    </a>
                  </li>
                  {/* <li>
                    <a
                      onClick={OpenImportPhoneNumberDiv}
                      className="btn btngroup m-btn"
                    >
                      <i className="la la-icon-font-size-13 la-upload"></i>
                      <span>Import</span>
                    </a>
                  </li>
                  <li className="m-portlet__nav-item">
                    <a
                      onClick={OpenBulkDeleteModel}
                      className="btn btngroup m-btn"
                    >
                      <span>
                        <i className="la flaticon-delete-1 la-icon-font-size-13"></i>
                        <span> Bulk Delete</span>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a onClick={() => ExportBacklinks(ClientID)} className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                      <i class="la la-icon-font-size-13 la-download"></i>Export
                    </a>

                  </li> */}
                </ul>
              </div>
            </div>
          </div>

          {/* Import Csv for */}
          {OpenImportPhoneNumber ? (
            <div className="portletbody" id="import_csv_whitelist">
              <div className="row">
                <div className="col-xl-8 offset-xl-2">
                  <h3 className="uploadheadingtitle">Import Phone Number</h3>
                  <div className="uplodfilesbox">
                    <CSVReader
                      accept={["text/csv", ".csv", "application/vnd.ms-excel"]}
                      onDrop={HandleOnDrop}
                      onError={HandleOnError}
                      addRemoveButton
                      removeButtonColor="#659cef"
                      onRemoveFile={HandleOnRemoveFile}
                      isReset={Reset}
                    >
                      <span>Select .CSV file</span>
                      <p>Add phone number data from csv file to import.</p>
                    </CSVReader>
                  </div>
                </div>
                <div className="col-xl-8 offset-xl-2 border-top pt-2 mt-4">
                  <div className="alignright">
                    <a
                      onClick={UploadCSVInPhoneNumber}
                      className="btn btn-primary btnColor"
                    >
                      <i className="la la-upload"></i> Upload
                    </a>
                    <a
                      onClick={CancelToUploadPhoneNumberCSV}
                      className="btn btn-secondary"
                    >
                      <i className="la flaticon-cancel"></i> Cancel
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          {/* Mapped div */}
          {ImportPhoneNumberMapBox ? (
            <div className="portletbody portletshadow" id="map_attribute_whitelist">
              <div className="row col-xs-12">
                <h4 className="headertitle float-left">Map attributes</h4>
              </div>
              <div className="col-xs-12">
                <div className="form-group m-form__group d-flex">
                  <div className="col-lg-2 nopadding">
                    <div>
                      <select
                        className="dbcontent form-control  m-input"
                        disabled="disabled"
                      >
                        <option selected="" value="Whitelist">
                          Phone Number
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-1 text-center">
                    <span className="fa fa-arrows-h fa-2x "></span>
                  </div>
                  <div className="col-lg-2 nopadding">
                    <div>
                      <select
                        id="PhoneNumber"
                        className="csvcontent form-control  m-input"
                        autocomplete="off"
                        disabled={DisabledFields}
                        value={
                          PhoneNumberList == null
                            ? DropboxData.findIndex(
                              (item) =>
                                item.toLowerCase() === "phone number"
                            )
                            : PhoneNumberList
                        }
                        onChange={(e) => {
                          SetPhoneNumberList(e.target.value);
                        }}

                      >
                        <option value="not mapped">Not Mapped</option>
                        {DropboxData.map((dbdata, index) => (
                          <option value={index}>{dbdata}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-2"></div>
                </div>
                <div className="col-lg-12">
                  <button
                    id="WhiteCSVImport"
                    disabled={DisabledFields}
                    onClick={SavePhoneNumberCSVDetails}
                    // onClick={() => { WhiteListSaveCsvBtn(); SetUpdateKey(UpdateKey + 1); }}
                    className="btn btn-primary btn-lightgreen"
                  >
                    <i className="la la-save"></i>Save
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          {/* Total Details for csv */}
          {DisplayPhoneCount ? (
            <CountsDisplay Total={PhoneNumberImportCount?.Total} Success={PhoneNumberImportCount?.Success} Duplicate={PhoneNumberImportCount?.Duplicate} Error={PhoneNumberImportCount?.Error} Invalid={PhoneNumberImportCount?.Invalid}/>
          ) : (
            <></>
          )}
          {/* Delete for csv */}
          {/* Bulk Csv for */}
          {OpenBulkDeletePhone ? (
            <div className="portletbody" id="delete_csv_whitelist">
              <div className="row">
                <div className="col-xl-8 offset-xl-2">
                  <h3 className="uploadheadingtitle">
                    Bulk delete phone number
                  </h3>
                  <div className="uplodfilesbox">
                    <CSVReader
                      accept={["text/csv", ".csv", "application/vnd.ms-excel"]}
                      onDrop={HandleOnDrop}
                      onError={HandleOnError}
                      addRemoveButton
                      removeButtonColor="#659cef"
                      onRemoveFile={HandleOnRemoveFile}
                      isReset={Reset}
                    >
                      <span>Select .CSV file</span>
                      <p>Add phone number data from csv file to bulk delete.</p>
                    </CSVReader>
                  </div>
                </div>
                <div className="col-xl-8 offset-xl-2 border-top pt-2 mt-4 border-top pt-2 mt-4 border-top pt-2 mt-4">
                  <div className="alignright">
                    <a
                      onClick={MappingBulkDeletePhone}
                      className="btn btn-primary btnColor"
                    >
                      <i className="la la-upload"></i> Upload
                    </a>
                    <a
                      onClick={CloseBulkDeleteModel}
                      className="btn btn-secondary"
                    >
                      <i className="la flaticon-cancel"></i> Cancel
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          {/* Mapped div */}
          {BulkPhoneNumberMapBox ? (
            <div className="portletbody portletshadow" id="map_attribute_whitelistdelete">
              <div className="row col-xs-12">
                <h4 className="headertitle float-left">Map attributes</h4>
              </div>
              <div className="col-xs-12">
                <div className="form-group m-form__group d-flex">
                  <div className="col-lg-2 nopadding">
                    <div>
                      <select
                        className="dbcontent form-control  m-input"
                        disabled="disabled"
                      >
                        <option selected="" value="WhitelistName2">
                          Phone Number
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-1 text-center">
                    <span className="fa fa-arrows-h fa-2x "></span>
                  </div>
                  <div className="col-lg-2 nopadding">
                    <div>
                      <select
                        id="WhitelistName1"
                        className="csvcontent form-control  m-input"
                        autocomplete="off"
                        value={
                          PhoneNumberList == null
                            ? DropboxData.findIndex(
                              (item) =>
                                item.toLowerCase() === "phone number"
                            )
                            : PhoneNumberList
                        }
                        onChange={(e) => {
                          SetPhoneNumberList(e.target.value);
                        }}
                      >
                        <option value="not mapped">Not Mapped</option>
                        {DropboxData.map((dbdata, index) => (
                          <option value={index}>{dbdata}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-2"></div>
                </div>
                <div className="col-lg-12">
                  <a
                    // onClick={() => { WhiteListBulkDeleteBtn(); SetUpdateKey(UpdateKey + 1); }}

                    onClick={BulkDeletingPhoneNumber}
                    className="btn btn-primary btn-lightgreen"
                  >
                    <i className="la la-save"></i>Delete
                  </a>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          {AddBox ? (
            <div className="addkeyword row my-4 mx-0" id="whitelistcontacview">
              <div className="col-sm-12 col-md-6 row">
                <label className="col-lg-3 view-lable-meet">Phone Number</label>
                <div className="col-lg-9">
                  <input
                    className="form-control m-input"
                    data-val="true"
                    data-val-required="Please enter Phone number"
                    id="phonenumber"
                    onBlur={() => PhoneNumberOnBlur()}
                    name="PhoneNumber"
                    placeholder="Enter phone number"
                    type="text"
                  />
                  <span style={{ color: "red" }} id="validation_email">
                    {Errors["PhoneNumberlist"]}
                  </span>
                </div>
              </div>
              <div className="col-lg-12 px-4 full-right border-top pt-3 mt-3">
                <button
                  disabled={phonenumberBtn}
                  className="btn btn-primary btn-lightgreen mr-1"
                  onClick={() => { PhoneNumberAdd(); SetUpdateKey(UpdateKey + 1); }}
                >
                  <i className="la la-save"></i> Save
                </button>
                <a
                  className="btn btn-secondary"
                  onClick={() => {
                    PhoneNumberClose();
                  }}
                >
                  <i className="la flaticon-cancel"></i> Cancel
                </a>
              </div>
            </div>
          ) : (
            <></>
          )}



<div className="row">
<div className="col px-0">
              <div className="table-bordered border-0 my-0">
                <PhoneNumberBlacklist 
                  addWhiteListed={PhoneNumberChaild}
                  updateFromChild={UpdateFromChild}
                  key={UpdateKey}
                />
              </div>
            </div>
          </div> 
        </TabPanel>
        <TabPanel value={value} className="tagpaddnone bg-white" index={3}>
            <div className="row">
            <div className="col">
              <div className="table-bordered border-0 my-0">
                <PhoneNumberDNC />
              </div>
            </div>
          </div> 
        </TabPanel>
      </div>  
    </div>
  );
}